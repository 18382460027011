.checkout-onepage-success {
    .product-item .items-attributes img{
        display: block;
        max-width: 60px;
    }
}

.order-number-successpage {
    text-align: center;
    @extend .f24;
}

.copiado {
    background: rgba(0,0,0,0.6);
    display: none;
    margin: 10px auto;
    padding: 6px;
    border-radius: 3px;
    font-size: 12px;
    color: #fff;
    text-transform: lowercase;
}

.completed-successpage {
    max-width: 626px;
    background-color: $white;
    margin: 0 auto;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    padding: 5px 30px 15px 30px;

    .valor-total {
        @extend .f20;
    }

    .clearboth {
        clear: both;
    }

    .summary-successpage {
        width: calc(50% - 20px);
        float: left;
        padding: 10px;
        box-sizing: content-box
    }

    .methods-successpage {
        width: calc(50% - 20px);
        float: right;
        padding: 10px;
        box-sizing: content-box
    }

    .content-items-successpage {
        padding: 0;
        border-bottom: 1px solid #C4C4C4;
    }

    .product-item {

        &:first-child {
            border: 0;
            padding-top: 0;
        }

        padding-top: 8px;

        .items-attributes {
            p {
                margin-bottom: 5px;
            }
        }

        hr {
            background: #C4C4C4;
            border: 0;
            width: 100%;
            height: 1px;
        }
        .image-items-successpage {
            float: left;
            max-width: 84px;
            margin-right: 19px;
        }
    }

    .delivery-adress-area {
        .delivery-adress {
            border: 1px dashed rgba(0, 0, 0, 0.4);
            padding: 15px 15px 5px 15px;
        }
        margin-bottom: 56px;
    }
}

.boleto-header {
    max-width: 686px;
    margin: 0 auto;
    padding: 5px 0px 15px 0px;
    padding-bottom: 50px;

    button {
        @include botao-padrao($black, $white, false);
        width: calc(50% - 15px);
        margin-right: 15px;
        border: 0;
        float: left;
        font-size: 14px;

        img {
            position: relative;
            top: 3px;
        }
    }

    .boleto-print {
        margin-right: 0;
        float: right;
    }

    .boleto-id {
        border: 2px dashed rgba(0, 0, 0, 0.3);
        @extend .f22;
        padding: 5px;
        text-align: center;
    }
}

@media only screen and (min-width: 1024px) {
    .deskoff {
        display: none;
    }
}

@media only screen and (max-width: 1024px) {

    .boleto-header {
        max-width: 686px;
        margin: 0 auto;
        padding: 5px 0px 15px 0px;
        padding-bottom: 12px;
        display: table;

        button {
            @include botao-padrao($black, $white, false);
            width: calc(100%);
            border: 0;
            float: left;
            margin-top: 8px;
            font-size: 14px !important;
        }
    }
    .completed-successpage {
        .summary-successpage {
            width: calc(100% - 20px);
            float: none;
            padding: 10px;
            box-sizing: content-box
        }
    
        .methods-successpage {
            width: calc(100% - 20px);
            float: none;
            padding: 10px;
            box-sizing: content-box
        }
    }
}

.checkout-onepage-success {
    background-color: #f3f3f3;
    
    .e-button {
        text-decoration: none;
        display: table;
        margin: 25px auto 0;
        @include botao-padrao($black, $white, false);
        padding: 10px 80px;
    }
}