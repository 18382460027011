.wrap-collabsible {
    margin-bottom: 16px;
}
.wrap-collabsible input[type='checkbox'] {
    display: none;
}
.lbl-toggle {
    background-color: #0098d4;
    border-radius: 7px;
    color: #ffffff;
    cursor: pointer;
    display: block;
    font-family: monospace;
    font-size: 1.2rem;
    font-weight: bold;
    padding: 1rem;
    text-align: center;
    text-transform: uppercase;
    transition: all 0.25s ease-out;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
}

.cms-index-index .product-item-details {
    text-align: center;
}

.lbl-toggle::after {
    border-bottom: 5px solid transparent;
    border-left: 5px solid white;
    border-top: 5px solid transparent;
    content: ' ';
    display: inline-block;
    margin-right: .7rem;
    margin-top: 5px;
    transform: translateY(-2px);
    transition: transform 0.2s ease-out;
    vertical-align: middle;
    float: right;
}
.toggle:checked + .lbl-toggle {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}
.toggle:checked + .lbl-toggle::after {
    transform: rotate(90deg) translateX(-3px);
}
.toggle:checked + .lbl-toggle + .collapsible-content {
    max-height: min-content;
}
.collapsible-content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.25s ease-in-out;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
}
.collapsible-content .content-inner {
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
    padding: .5rem 1rem;
}

.product-info-main .product-info-price .best-installment {
    text-align: left !important;
}
.dizy-installment .best-installment {
    color: #575757;
    margin: 5px 0 5px;
    overflow: hidden;
    text-transform: uppercase;
    white-space: nowrap;
    text-align: center;
    font-size: 11px;
}
.dizy-installment .footer-message {
    color: #a9a9a9;
    font-size: 9px;
    line-height: 1.2;
}

.dizy-installment .installments-options {
    padding: 5px 10px;
}
.dizy-installment .installments-options .installment-option {
    list-style: none;
    margin: 5px 0;
}

.dizy-installment .discounts ul {
    list-style: none;
    padding: 0;
}


.block-minicart table td{
    padding: 0;
}

#minicart-installment {
    font-size: 12px;
    text-align: right;
    position: relative;
    right: 30px;
}

.block-minicart table .total-label{
    font-size: 14px;
    position: relative;
    top: 10px;
}

#minicart-grandtotal{
    font-size: 16px;
}
