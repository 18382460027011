.accordion-mc {
    background-color: #FFF;
    border-radius: 8px;
    margin-bottom: 24px !important;
    -webkit-box-shadow: 1px 0px 5px 0px rgba(176, 176, 176, 0.6);
    -moz-box-shadow: 1px 0px 5px 0px rgba(176, 176, 176, 0.6);
    box-shadow: 1px 0px 5px 0px rgba(176, 176, 176, 0.6);
    position: relative;
    
    [aria-expanded="false"]::after {
        content: '\e621';
        font-family: 'luma-icons';
        vertical-align: middle;
        display: inline-block;
        font-weight: normal;
        overflow: hidden;
        speak: none;
        text-align: center;
        position: absolute;
        right: 16px;
    }

    [aria-expanded="true"]::after,
    .block-dashboard-orders [aria-expanded="true"]::after,
    .block-addresses-default [aria-expanded="true"]::after {
        content: '\e622';
        font-family: 'luma-icons';
        vertical-align: middle;
        display: inline-block;
        font-weight: normal;
        overflow: hidden;
        speak: none;
        text-align: center;
        position: absolute;
        right: 16px;
        
    }

    .block-content {
        padding: 0px 3.1%;
    }

    .block-title strong {
        @extend .f20;
    }
    
    div[aria-expanded="false"] strong {
        font-size: 14px !important;
        font-weight:600 !important;
    }
    
}