.catalog-category-view .product-item-photo video {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
}

.navigation .submenu {
    z-index: 99;
}

.product-item-info:hover {
    padding: 0 !important;
    margin: 0 !important;
}


.catalog-category-view .product-item-info {
    position: relative;
}

.catalog-category-view.page-layout-1column.page-products,
.catalogsearch-result-index.page-layout-1column.page-products {

.column.main {
    padding-top: 0px;
}

.products-grid .product-item-info:hover, .products-grid .product-item-info.active {
    background: #fff;
    box-shadow: none;
    border: 0;
    margin: -10px;
    padding: 9px;
    position: relative;
    z-index: 2;
}

.catalog_gallery .dizy-installment .best-installment  {
    display: none;
}

.product-item-details .installments {
    position: relative;
    top: -25px;
}

.catalog_gallery{


    .mode-list:before {
        content: url(../images/alist.svg);
    }

    .mode-grid:before {
        content: url(../images/agrid.svg);
    }

    #layered-filter-block{
        background: #E8E8E8 !important;
    }

    .modes-mode {
        background: transparent;
        border: 0;
        box-shadow: none;
        padding: 7px 5px;
    }

    .modes-mode:hover {
        cursor: pointer;
    }
    
    .toolbar .sorter-action{
        display: none;
    }

    .product-item-info:hover {
        margin: 0 !important;
        padding: 0 !important;
    }

    .product-item-details > .block-static-block{
        display: inline-block;
        float: right;
        margin-bottom: 10px;
    }


    .product-item-inner{
        clear: both;
    }

    .toolbar-products {
        z-index: 9;
        position: relative !important;
    }

    .page-title-wrapper {
        display: none;
    }

    
    @media only screen and (min-width: 1024px) {
        
        .toolbar-products {
            max-width: 300px;
            display: inline-block;
            float: right;
        }

        .modes {
            position: relative;
            float: right;
            margin-right: 0;
            top: 4px;
        }

    }

    @media (max-width: 1023px) {


        .amconf-matrix-table-wrap .amconf-matrix-arrow {
            top: 6px;
            position: relative;
        }


        .amconf-matrix-table-wrap .amconf-matrix-arrow.-plus {
            -ms-flex-order: 3;
            -webkit-order: 3;
            order: 3;
            -webkit-transform: rotate(180deg);
            -moz-transform: rotate(180deg);
            -ms-transform: rotate(180deg);
            transform: rotate(180deg);
        }
        

        .amconf-matrix-table-wrap .amconf-matrix-arrow {
            display: inline-block;
            width: 13px;
            height: 25px;
            margin-top: 3px;
            background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iMjEiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTEzIDE5LjExMlYxLjcxOEMxMyAuMzAzIDExLjMxNC0uNDMxIDEwLjI3OC41MzNMMS4yMzMgOC45NDlhMS42MTkgMS42MTkgMCAwIDAtLjAzNyAyLjMzNWw5LjA0NSA4Ljk3N2MxLjAyMSAxLjAxNCAyLjc1OS4yOSAyLjc1OS0xLjE0OXoiIGZpbGw9IiNDMkMyQzIiLz48L3N2Zz4=) no-repeat center;
            -webkit-transition: background 0.3s ease;
            -moz-transition: background 0.3s ease;
            -ms-transition: background 0.3s ease;
            transition: background 0.3s ease;
            cursor: pointer;
        }

        .amconf-matrix-table-wrap .amconf-matrix-arrow {
            display: inline-block;
            width: 13px;
            height: 25px;
            margin-top: 3px;
            background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iMjEiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTEzIDE5LjExMlYxLjcxOEMxMyAuMzAzIDExLjMxNC0uNDMxIDEwLjI3OC41MzNMMS4yMzMgOC45NDlhMS42MTkgMS42MTkgMCAwIDAtLjAzNyAyLjMzNWw5LjA0NSA4Ljk3N2MxLjAyMSAxLjAxNCAyLjc1OS4yOSAyLjc1OS0xLjE0OXoiIGZpbGw9IiNDMkMyQzIiLz48L3N2Zz4=) no-repeat center;
            -webkit-transition: background 0.3s ease;
            -moz-transition: background 0.3s ease;
            -ms-transition: background 0.3s ease;
            transition: background 0.3s ease;
            cursor: pointer;
        }

        .toolbar-sorter {
            right: 12px;
        }

        .filter-title strong {
            margin-left: 12px;
        }

        & > .columns {
            padding: 0 !important;
        }

        .carousel-main-image {
            margin-top: 20px;
        }

        &.page-main{
            padding: 0 !important;
            width: 100vw !important;
        }
    }

    .product-item-name {
        margin: 0;
        text-align: left;
        a {
            text-transform: uppercase;
        }
    }

    .price-box {
        margin-top: 0;
        .price {
            font-size: 16px;
        }
    }
    
    .normal-price {
        padding: 5px 0;
    }

    .old-price {
        padding-right: 8px;
    }

    .amconf-matrix-table-wrap {
        .swatch-option {
            border-radius: 0;
            background: transparent;
            min-width: 15px;
            height: 18px;
            border: 0;
        }    
    }
    

    .swatch-option.color { 
        border-radius: 100%;
    } 

    .amconf-matrix-table-wrap .amconf-matrix-input {
        border: 1px solid #777;
    }

    .amconf-matrix-qty {
        color: #777;
    }

    .slick-next.slick-arrow{
        background: url(../images/direita.png) no-repeat no-repeat !important;
        width: 64px;
        height: 64px;
        z-index: 4;
        scale: 0.8;
    }

    .slick-prev.slick-arrow{
        background: url(../images/direita.png) no-repeat no-repeat !important;
        width: 64px;
        height: 64px;
        z-index: 4;
        scale: 0.8;
    }

    img[alt~="img-tabela-medidas"] {
        text-align: right;
    }
    
    &.products-grid {

        .price-box span {
            width: initial !important;
        }

        .product-item-details {
            padding: 24px;
            box-sizing: border-box ;
        }

        .carousel-main-image, .carousel-small-image {
            padding: 0;
        }


        .product-item-info {
            width: 100%;
        }

        .product-item-inner{
            width: 100%;
            overflow: initial;
        }

        @media only screen and (min-width: 1024px) {
            .product-item-details {
                width: 50%;
                float: right;
            }

            .dizy-catalog{
                width: 50%;
                float: left;
            }
        }
        .swatch-option.color { 
            border-radius: 100%;
        }

        .actions-secondary {
            display: none;
        }

        .swatch-attribute-options{
            text-align: left;
            margin-top: 100px;
        }

        .price-box span {
            font-size: 16px !important;
        }

        @media only screen and (max-width: 1023px) {
            .product-item{
                width: 100% !important;
                margin: 0;
            } 

            .product-item-inner {
                display: block !important;
            }

            .carousel-small-images {
                display: none;
            }

            .amconf-matrix-table-wrap{
                margin-top: 0px;
            }
        }

    }

    .amconf-matrix-table-wrap .amconf-matrix-row {
        border-bottom: 1px solid #c4c4c4;
    }
    
    @media (max-width: 1023px) {
        .filter-title strong,.toolbar select {
            border: 0;
            color: #777777;
            font-size: 12px;
            background: #E8E8E8;
            border-radius: 7px;
            text-transform: uppercase;
        } 
    }

    .filter-title {
        margin-left: 8px;
    }

    .swatch-option {
        min-width: 40px;
        height: 40px;
    }

    &.products-list{
        .carousel-main-image, .carousel-small-image {
            width: 100vw;
        }

        .product-item-details{
            width: 100vw;
        }
    
        .product-item-details{
            width: 100vw;
        }

        @media only screen and (max-width: 1023px) {

            .product-item-details{
                display: block;
                padding: 10px;
                box-sizing: border-box;
            }

            .carousel-main-image {
                padding: 0;
            }
/*             .slick-slider {
                padding: 20px 0;
            } */

            .swatch-attribute.color {
                padding-top: 30px;
            }

            
        }

        @media only screen and (min-width: 1024px) {
            margin-top: 48px;
            
            .slick-next.slick-arrow{
                left: 99%;
            }
    
            .slick-prev.slick-arrow{
                left: -60px;
            }

            .swatch-attribute.color {
                padding-top: 60px;
            }

            .carousel-main-image, .carousel-small-image {
                width: 55vw;
                position: relative;
                padding: 0;
            }

            .product-items{
                padding: 0 2.9%;
            }

            .product-item-details{
                width: 19.3vw;
                padding-right: 9vw;
            }

            .dizy-installment {
                margin-bottom: 30px;
            }
        }
    }

    .amconf-matrix-table-wrap .amconf-matrix-title {
        line-height: 15px;
    }

    .swatch-attribute.amconf-matrix-observed {
        padding-top: 0;
    }

    .description-toggle-wrapper {
        z-index: 3;
        background-color: transparent;

        .description-toggle-button, .description-text {
            background-color: #DDDDDD;
        }
    }

    .amconf-matrix-table-wrap .amconf-matrix-arrow {
        scale: 0.5;
    }

    .amconf-matrix-table-wrap .amconf-matrix-input {
        margin: 0;
        max-width: 40px;
    }

    .amconf-matrix-table-wrap .amconf-cell {
        font-size: 10px;
        color: #777;
        text-transform: uppercase;
    }

     .amconf-matrix-table-wrap {
        max-width: 100%;
        width: 100%;
    }

    .amconf-matrix-table-wrap .amconf-matrix-price {
        font-weight: normal;
        font-size: 12px;
        color: #777;
        vertical-align: middle;
    }

    .amconf-matrix-subtotal {
        font-size: 12px;
        color: #777;
        vertical-align: middle;
    }

    .amconf-matrix-table-wrap .amconf-matrix-qty {
        font-size: 12px;
        font-family: #777;
        vertical-align: middle;
    }

    button.action.primary {
        padding: 20px 0;
        background-color: #55960A;
        border-color: #55960A;
        text-transform: uppercase;
        width: 100%;
        margin-top: 20px;
    }

    .product-item-description .action.more {
        display: none;
    }
    

    .amconf-matrix-table-wrap {
        margin-top: 38px;
    }

    .product-item-actions .actions-primary {
        width: 100%;
    }

    .amconf-matrix-full-subtotal {
        border-bottom: 0;
        font-size: 14px;
        font-weight: bold;
        text-transform: uppercase;
        color: #777;
        vertical-align: middle;
    }

    .matrix-installment {
        margin-top: 10px;
        font-size: 12px;
        text-transform: none;
    }


    .product-item {
        margin-bottom: 50px;
    }

    .swatch-attribute-options {
        margin-top: 30px;
    }

    .dizy-installment .best-installment {
        text-align: left;
    }
    
}

.catalog_gallery .swatch-attribute-options {
    padding: 10px 0; 
}

.catalog_gallery #amasty-shopby-product-list > .amlabel-position-wrapper{
    display: none !important;
}

.catalog_gallery #toolbar-amount {
  display: none;
}


.dizy-catalog, #amasty-main-container, .carousel-main-image{
    position: relative;
}



@media only screen and (min-width: 900px) {


    .filter.block{
        width: 100vw;
        z-index: 1;
        position: relative;
        left: -4vw;
        background: #f5f5f5;
        padding: 0 4vw;
        border-top: 1px solid #F1F1F1;
        margin-bottom: 40px;
        box-sizing: border-box;
    }

    .filter-options-title:after {
        right: 5px;
        top: 9px;
        z-index: 3;
        font-size: 10px;
        color: #777;
        position: absolute;
    }

    .filter-options-title {
        border: 1px solid #ddd;
        background: #fff;.catalog-category-view.page-layout-1column.page-products {
            .amasty-catalog-topnav .filter-options {
                margin-bottom: 6px;
            }
        
            .products-grid .product-item-info:hover,
        .products-grid .product-item-info.active {
            background: #fff;
            box-shadow: none;
            border: 0;
            margin: -10px;
            padding: 9px;
            position: relative;
            z-index: 2;
        }
        
        
        .column.main {
            padding-top: 0px;
        }
        
        .products-grid .swatch-option {
            float: none;
            display: inline-block;
            border: 1px solid #ccc;
        }
        
        .products-grid .swatch-option.text {
            background: #fff;
            font-weight: 500;
            padding: 3px 5px;
        }
        
        .products-grid .product-item-inner {
            background: #fff;
            box-shadow: none;
            border: 0;
            border-top: none;
            left: 0;
            margin: 10px 0 0 -1px;
            padding: 0;
            position: relative;
            right: -1px;
            z-index: 2;
        }
        
        .products-grid .product-item-details {
            text-align: center;
            padding-top: 0;
        }

        
        .products-grid .product-item-details .installments p {
            font-size: 14px;
            margin-top: -10px;
            font-weight: 700;
        }
        
        .products-grid .product-item-name .product-item-link {
            font-size: 12px;
            font-weight: 400;
            text-transform: uppercase;
            letter-spacing: 1px;
        }
        
        .products-grid .product-item .price-box span {
            font-size: 13px;
            font-weight: normal;
            width: 100%;
            font-weight: 400;
        }
        
        .products-grid .action.primary {
            text-transform: uppercase;
            font-weight: 400;
            padding: 9px 25px;
            border:none;
        }
        
        .products-grid .price-container {
            display: block;
            text-align: center;
            width: 100%;
        }
        
        .products-grid .amlabel-position-wrapper {
            font-weight: 400;
        }
        
        .products-grid .product-item .action.towishlist, .products-grid .product-item .action.towishlist:hover{
            display: block;
        }
        
        .product-item-actions .actions-secondary>.action:hover, .product-item-actions .actions-secondary>.action {
            display: none;
        }
        
        .catalog-product-view .product-info-main .product.attribute.sku {
            margin-top: -15px;
        }
        
        .products-grid .product-item-info:hover, .products-grid .product-item-info.active {
            background: #fff;
            box-shadow: none;
            border: 0;
            margin: -10px;
            padding: 9px;
            position: relative;
            z-index: 2;
        }
        
        
        .product-item .price-box {
            position: relative;
            margin-bottom:  0;
            align-items: flex-end;
        }
        
        .product-item-details .installments {
            position: relative;
            top: -25px;
        }
        
        
        @media only screen and (min-width: 900px) {
        
        
            .filter.block{
                width: 100vw;
                z-index: 1;
                position: relative;
                left: -4vw;
                background: #f5f5f5;
                padding: 0 4vw;
                border-top: 1px solid #F1F1F1;
                margin-bottom: 40px;
                box-sizing: border-box;
            }
        
            .filter-options-title:after {
                right: 5px;
                top: 9px;
                z-index: 3;
                font-size: 10px;
                color: #777;
            }
        
            .filter-options-title {
                border: 1px solid #ddd;
                background: #fff;
                font-size: 11px;
                padding: 8px 40px 8px 10px !important;
                text-transform: none;
                font-weight: 400;
                color: #777;
            }
        
            .sorter{
                display: block;
                position: relative;
                z-index: 6;
                top: -16px;
            }
        
            .toolbar select {
                background-color: #fff;
                box-shadow: none;
                border-radius: 0;
            }
        
            .toolbar select{
                font-size: 11px;
                text-transform: none;
                font-weight: 400;
                color: #777;
            }
        
            .sorter-label{
            display: none;
            }
        
            .filter-actions~.filter-options{
                float: left;
                margin-top: 0;
            }
        
            .products-grid .product-item {
                width: 25%;
                margin-left: 0;
            }
        
            .product-item-info {
                width: 100%;
            }
        
            .toolbar-products{
            margin-bottom: 0;
            }
        
            .column.main{
                padding-top:0;
            }
        
            .products{
                margin:0
            }
        
            .page-main{
                min-width: 90vw;
            }
        
            .filter-current .item,
            .filter-current .items{
                display: none;
            }
        
            .filter-actions{
                float: right;
                font-size: 11px;
                position: absolute;
                margin-top: 10px;
            }
        
            .page-title-wrapper{
                display: none;
            }
        
            .toolbar-products {
                margin-top:  -65px;
            }
        
            .product-item strong + div + .price-box {
                min-height: 88px;
            }
        
        }
        @media only screen and (max-width: 900px) {
        
        .products-grid .product-item-inner{
        display:none;
        }
        
        .filter.active .filter-title {
        background: #fff;
        }
        
        .products-grid .swatch-option.text {
            padding: 0px 0px;
            font-size: 10px;
            min-width: 19px;
            color: #AAA;
        }
        
        .product-item strong + div + .price-box {
            min-height: 75px;
        }
        
        }
        
        
        .swatch-option {
        min-width: 18px;
        }
        
        .filter-options-title {
            font-size: 11px;
            letter-spacing: 1px;
            font-weight: 400;
        }
        
        .filter-options-content .items a {
            margin-left: 0;
            font-size: 12px;
            text-transform: uppercase;
        }
        
        
        .filter-options-content .am-shopby-swatch-label .label {
            text-transform: uppercase;
            font-size: 12px;
        }
        
        .filter .block-subtitle {
            display: none;
        }
        
        
        .action.towishlist{
            background: #000;
            color: #fff !important;
            padding: 3px;
            border-radius: 3px;
        }
        
        .product-item-actions .actions-primary+.actions-secondary>.action {
            line-height: 25px;
        }
        
        .products-grid .product-items, body {
            animation-name: fadeIn;
            animation-fill-mode: both;
            animation-duration: 2s;
        }
        
        .products-grid .product-items {
            animation-duration: 4s;
        }
            
        }
        
        font-size: 11px;
        padding: 8px 40px 8px 10px !important;
        text-transform: none;
        font-weight: 400;
        color: #777;
    }

    .toolbar select {
        background-color: #fff;
        box-shadow: none;
        border-radius: 0;
    }

    .sorter-label{
        display: none;
    }

    .filter-actions~.filter-options{
	    float: left;
	    margin-top: 0;
	}

    .products-grid .product-item {
        width: 25%;
        margin-left: 0;
    }

    .product-item-info {
        width: 100%;
    }

    .toolbar-products{
        margin-bottom: 0;
    }

    .column.main{
    	padding-top:0;
    }

    .products{
    	margin:0
    }

    .page-main{
    	min-width: 90vw;
    }

    .filter-current .item,
    .filter-current .items{
    	display: none;
    }

    .filter-actions{
        float: right;
        font-size: 11px;
        position: absolute;
        margin-top: 10px;
    }

    .product-item strong + div + .price-box {
        min-height: 88px;
    }

    .filter-options {
        clear: initial;
    }

}

@media only screen and (max-width: 900px) {

.filter.active .filter-title {
background: #fff;
}

.products-grid .swatch-option.text {
    padding: 0px 0px;
    font-size: 10px;
    min-width: 19px;
    color: #AAA;
}

.product-item strong + div + .price-box {
    min-height: 75px;
}

}

@media only screen and (max-width: 1024px) {
    .products-grid .product-item-details .installments p {
        font-size: 12px !important;
    }


    .products-grid .product-item-name .product-item-link {
        font-size: 12px !important;
    }

    .products-grid .product-item .price-box span {
        font-size: 12px ;
    }

}

.filter-options-title {
    font-size: 11px;
    letter-spacing: 1px;
    font-weight: 400;
}

.filter-options-content .items a {
    margin-left: 0;
    font-size: 12px;
    text-transform: uppercase;
}


.filter-options-content .am-shopby-swatch-label .label {
    text-transform: uppercase;
    font-size: 12px;
}

.filter .block-subtitle {
    display: none;
}

.products-grid .product-items, body {
    animation-name: fadeIn;
    animation-fill-mode: both;
	animation-duration: 2s;
}

.products-grid .product-items {
	animation-duration: 4s;
}
    
}

.catalog-category-view.page-layout-1column.page-products,
.catalogsearch-result-index.page-layout-1column.page-products {
    @media (min-width: 767px) {

        .block-content.filter-content {
            min-height: 44px;
        }

    }
}

.catalog-category-view.page-layout-2columns-left.page-products,
.catalog-category-view.page-layout-1column.page-products,
.catalogsearch-result-index.page-layout-1column.page-products,
.catalogsearch-result-index.page-layout-2columns-left.page-products {
    .breadcrumbs {
        display: none
    }

    @media (min-width: 767px) {
        .page-title-wrapper{
            display: none;
        }

        .toolbar select{
            font-size: 11px;
            text-transform: none;
            font-weight: 400;
            color: #777;
            background-color: #fff;
            border-radius: 0;
        }

    }

    .products-grid .product-item-info:hover,
    .products-grid .product-item-info.active {
        background: #fff;
        box-shadow: none;
        border: 0;
        margin: -10px;
        padding: 9px;
        position: relative;
        z-index: 2;
    }

    .products-grid .product-item-details {
        text-align: center;
        padding-top: 0;
    }

    .product-item .price-box {
        position: relative;
        margin-bottom:  0;
        align-items: flex-end;
    }

    .products-grid .product-item .price-box span {
        font-size: 13px;
        font-weight: normal;
        width: 100%;
        font-weight: 400;
    }

    .products-grid .price-container {
        display: block;
        text-align: center;
        width: 100%;
    }

    .products-grid .swatch-option {
        float: none;
        display: inline-block;
        border: 1px solid #ccc;
    }
    
    .products-grid .swatch-option.text {
        background: #fff;
        font-weight: 500;
        padding: 3px 5px;
        margin-top: 1px;
    }
    
    .products-grid .product-item-inner {
        background: #fff;
        box-shadow: none;
        border: 0;
        border-top: none;
        left: 0;
        margin: 10px 0 0 -1px;
        padding: 0;
        position: relative;
        right: -1px;
        z-index: 2;
    }


    .products-grid .product-item-details .installments p {
        font-size: 14px;
        margin-top: -10px;
        font-weight: 700;
    }
    
    .products-grid .product-item-name .product-item-link {
        font-size: 12px;
        font-weight: 400;
        text-transform: uppercase;
        letter-spacing: 1px;
    }
    
    .products-grid .action.primary {
        text-transform: uppercase;
        font-weight: 400;
        padding: 9px 25px;
        border:none;
    }
    
    .products-grid .amlabel-position-wrapper {
        font-weight: 400;
    }
    
    .products-grid .product-item .action.towishlist,
    .products-grid .product-item .action.towishlist:hover{
        display: block;
    }
    
    .product-item-actions .actions-secondary>.action:hover,
    .product-item-actions .actions-secondary>.action {
        display: none;
    }

    .swatch-option {
        min-width: 18px;
    }

    .products-grid + .toolbar.toolbar-products {
        display: none;
    }

    
    .action.towishlist{
        background: #000;
        color: #fff !important;
        padding: 3px;
        border-radius: 3px;
    }

    .product-item-actions .actions-primary+.actions-secondary>.action {
        line-height: 25px;
    }

    @media (max-width: 767px) {
        .products-grid .product-item-inner{
            display:none;
        }
    }
}

.catalogsearch-result-index.page-layout-1column.page-products {
    main#maincontent {
        width: 92vw;
    }

    .amasty-catalog-topnav .filter-options {
        margin-top: 0;
        padding: 0;
        border: none;
    }

/*     @media (min-width: 767px) {
        .sidebar.sidebar-main .block.filter {
            display: none;
        }
    } */
}
.toolbar-bottom .toolbar-sorter,.toolbar-bottom .limiter,.toolbar-bottom .toolbar-products .toolbar-amount {display: none;}

.catalog-category-view.page-layout-2columns-left.page-products,
.catalogsearch-result-index.page-layout-2columns-left.page-products {
    .catalog-topnav.amasty-catalog-topnav {
        display: none;
    }

    main#maincontent {
        width: 92vw;
    }

    .block-content.filter-content {
        strong.block-subtitle.filter-subtitle,
        span.block-subtitle.filter-current-subtitle {
            display: none;
        }

        .filter-options-title {
            font-weight: 400;
            font-size: 12px;
            letter-spacing: 1px;
        }

        .filter-options-item {
            padding-bottom: 20px;
            padding-top: 10px;
        }

        .swatch-option {
            min-width: 18px;
        }
    }

    @media (min-width: 767px) {
        .toolbar-products {
            margin-bottom: 0;
        }

        .products-grid {
            margin: 0;
        
            li.item.product.product-item {
                width: calc((100% - 6%)/3);
            }
        
        }

        .product-item-info {
            width: 100%;
        }

    }
}


.page-layout-2columns-left .toolbar-sidebar{
    display: none;
}

.page-layout-1column .toolbar-products{
    display: none !important;
}

.catalog_gallery #amasty-shopby-product-list :nth-child(3).toolbar-products {
    display: none;
}

.catalog_gallery #amasty-shopby-product-list :nth-child(5).toolbar-products {
    display: none;
}

.catalog_gallery #amasty-shopby-product-list :nth-child(4).toolbar-products {
    display: none;
}

.sorter-options {
    color: #777;
    font-size: 12px;
}

.description-toggle-wrapper {
    background-color: #DDDDDD;
  }
  
  .description-toggle-button {
    cursor: pointer;
    padding: 8px 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .description-button-label {
    text-transform: uppercase;
    font-weight: bolder;
    opacity: 0.6;
  }
  
  .description-plus-signal {
    font-weight: bolder;
    font-size: 18px;
    opacity: 0.6;
  }
  
  .description-text {
    border-top: 1px solid gray;
    padding: 8px 12px;
    background: rgba(255, 255, 255, 0.4);
  }

  .catalog-category-view, .catalogsearch-result-index {
    .ndd-uberzoom-fullscreen {
        left: 8px !important;
        top: 8px !important;
        margin: 0 !important;
        width: 19px !important;
        height: 19px !important;
        background: rgba(0,0,0,0.20);
    }
    
    
    .ndd-uberzoom-fullscreen img{
        margin: 2px;
        width: 15px !important;
        height: 15px;
    }
}
