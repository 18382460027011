
.amrelated-grid-wrapper {
    .block-title {
        margin: 0 0 10px;
        strong {
            @extend .f18;
            text-transform: uppercase;
            font-weight: normal;
            text-align: center;
            display: block;
            margin: 0 auto;
        }
    }

    .amrelated-name {
        text-transform: uppercase;
    }

    .slick-dots {
        position: relative;
    }

    .products-grid .product-item-info:hover, .products-grid .product-item-info.active {
        padding: 0 !important;
        margin: 0 !important
    }

/*     .widget-product-grid {
        display: inline-table;
    } */

    .products-grid .product-item-info:hover, .products-grid .product-item-info.active {
        box-shadow: none;
    }

    img.img-flipper{
        display:none;
    }

    .product-item:hover img.img-flipper{
        display: block;
    }

    .product-items.slick-slider .slick-slide .product-item {
        width: 100% !important;
        display: block !important;
    }

    .product-item-details {
        padding-bottom: 30px;
    }

    .slick-slide {
        vertical-align: top;
    }

    .product-items.slick-slider .slick-slide .product-item {
        max-width: none !important;
    }


    .actions-secondary {
        display: none !important;
    }

    .product-item-actions {
        text-align: center
    }

    .product-item-name a {
        @extend .f12;
        text-transform: uppercase;
        text-align: center;
        display: block;
    }

    .price-container span {
        @extend .f12;
        text-align: center;
        display: block;
        margin: 0 auto;
    }

    .products-grid .product-item-info:hover {
        border: 0;
    }

    .swatch-option.text {
        margin-right: 8px;
        padding: 4px 7px;
    }

    .products-grid .product-item {
        float: left;
        position: relative;
    }

    .actions-primary {
        margin-top: 6px;
    }

    .swatch-attribute-options{
        display: table;
        margin: 10px auto;
    }


    li.product-item .product-item {
        width: 100% !important;
    }

    .block.widget .products-grid .product-item .product-item-inner, .page-layout-1column .block.widget .products-grid .product-item .product-item-inner, .page-layout-3columns .block.widget .products-grid .product-item .product-item-inner {
        border: 0;
        box-shadow: none;
        margin: 0;
    }

    .products-grid .product-item-info:hover > .product-item-photo > .product-image-container{
        opacity: 0;
    }

    .products-grid .product-item-info:hover {
        background: transparent;
    }


    .product-item-photo {
        display: block;
        position: relative;
    }

    li.product-item .product-item {
        width: 100% !important;
    }

    img.img-flipper {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
    }

    .amlabel-position-wrapper {
        display: none !important;
    }

    .price-box {
        text-align: center;
        .old-price {
            display: inline-block;
            position: relative;
            top: -2px;
            padding: 0;
            margin: 2px auto;
            margin-left: 5px;
        }
        .normal-price{
            display: block;
            padding: 0;
            margin: 0 auto;
        }
        .price {
            @extend .f14;
        }
    }
    .eth_installments {
        top: 0 !important;
        text-align: center;
        font-size: 14px;
    }

    .product-item .price-box {
        margin: 10px 0 10px;
    }


    @media screen and (max-width:1023px){

        .product-items.slick-slider .slick-slide .product-item {
            padding: 0;
        }

        .amrelated-pack-item .price-box {
            margin-bottom: 30px;
        }

        .block-title {
            text-align: center;
            strong {
                text-align: center;
            }
        }

        .widget-product-grid {
            padding:  0 !important;
        }
    }
}

/*
@media screen and (max-width:1023px){
    .product-image-container {
        display: initial !important;
    }
}
*/