
.jq-toast-wrap {
  margin-right: 60px;
}

.fieldset>.field>.label, .fieldset>.fields>.field>.label {
  font-weight: 400;
}

.slick-prev:before,
.slick-next:before {
  color: #000;
}

.loggout-button {
  button {
    font-weight: normal;
    @extend .f14;
    @include botao-padrao($black, $white, false);
    width: 100%;
  }
}

.slick-dots li button, .slick-prev, .slick-next {
  box-shadow: none !important;
}


.customer-account-index {
  background-color: #f3f3f3;

  address {
    line-height: 24px !important;
  }

  #minhaconta {
    select {
      padding: 0 5px;
      @include select-padrao($light-gray, $black-80);
      height: 45px;
    }

    #add-address select {
      @include select-padrao($white, $black-80);
      -webkit-box-shadow: 1px 0px 5px 0px rgba(176,176,176,0.6);
      box-shadow: 1px 0px 5px 0px rgba(176,176,176,0.6);
      border: 0;
      border-radius: 3px;
      height: 32px;
    }

    .toolbar select {
      padding: 0 10px;
    }
    .block-my-trades{
      .info {
        background: #fff;
        padding-left: 0;
        padding: 0;
        color: #000;
        text-align: left;
      }

      .block-content {
        padding-bottom: 10px;
      }
    }

    .block-title {
      margin: 0 !important;
      padding: 0 !important;
      border: 0 !important;
      padding: 10px 3.1% !important;
    }

    .block-title:hover {
      cursor: pointer;
    }

    .info {
      width: 100%;
      margin-bottom: 0;
      padding: 10px 0 20px 0;
    }

    .field-name-firstname input {
      width: 90%;
    }

    .field-name-firstname,
    .field-name-lastname {
      width: 50%;
      float: left;
    }

    .form-edit-account {
      width: 90%;
    }

    .field label {
      @extend .f12;
      position: relative;
    }

    .customer-addresses-toolbar .pager {
      width: 50%;
      display: block;
      margin: 0 auto;
    }

    max-width: 844px;
    margin: 0 auto;
  }

  .dn,
  #minhaconta .current,
  #minhaconta #current-password,
  #change-email,
  #change-password,
  #minhaconta > .block-dashboard-addresses,
  .form-address-edit .telephone label {
    display: none;
  }

  .page-title-wrapper {
    text-align: center;
  }

  #add-address {
    position: relative;
  }

  .w-50 {
    width: 50%;
    float: left;
  }

  .box-information,
  .box-newsletter {
    width: 100% !important;
    padding: 14px 0;
  }

  .box-billing-address,
  .box-shipping-address {
    width: 100% !important;
    padding: 8px;
    box-sizing: content-box;
    float: none !important;
  }

  #my-returns-table .e-button,
  #my-returns-table .e-button:hover {
    color: #fff !important;
    font-weight: normal;
    text-decoration: none;
  }

  .wishlist .box-tocart {
    text-align: center;
  }

  .wishlist .item {
    padding-bottom: 25px;
  }

  .block-my-wish .block-content {
    padding: 0px 2.1%;
  }

  .block-my-wish .products-grid .product-item-info:hover,
  .block-my-wish .products-grid .product-item-info.active,
  .block-my-wish .products-grid .product-item-inner {
    background: #fff;
    box-shadow: none;
    border: 0;
    padding: 0;
    margin: 0;
  }

  .message.info>*:first-child:before {
    display: none !important;
  }

  .block-my-wish .a-content {
    padding: 0 10px;
  }

  .products-grid.wishlist .product-item-info:hover .product-item-inner {
    display: block !important;
  }

  .block-my-wish .product-item-info {
    width: 100%;
  }

  .block-my-wish .box-tocart {
    margin: 0;
  }

  .block-my-wish .products-grid .product-item-inner:first-child {
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
  }

  .block-my-wish .fieldset {
    margin: 0;
  }

  .block-my-wish .product-item-actions {
    margin: 0 !important;
    text-align: center;
    padding: 4px 8px;
  }

  .block-my-wish .products-grid .product-item-inner:last-child {
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
  }

  .block-my-wish .products-grid.wishlist .product-item .box-tocart input.qty {
    width: 24px;
    @extend .f14;
    height: 24px;
    border: 1px solid #e4e4e4;
    border-radius: 3px;
  }

  .block-my-wish .box-tocart .qty {
    display: table !important;
    margin: 0 auto;
  }

  .block-my-wish .box-tocart .qty .label {
    float: left;
    color: #000;
    font-size: 8px;
    line-height: 27px;
    margin: 0;
    margin-right: 5px;
  }

  .block-my-wish .box-tocart .qty .control {
    display: inline-block;
  }

  .wishlist .price-as-configured {
    text-align: center;
    padding-bottom: 8px;
  }

  .wishlist .box-atributes {
    text-align: center;
    display: table;
    margin: 0px auto;
    padding-bottom: 8px;
  }

  .wishlist .box-atributes .label {
    float: left;
    @extend .f14;
    font-weight: bold;
    margin-right: 4px;
  }

  .wishlist .box-atributes .values {
    @extend .f14;
    float: left;
    margin-right: 12px;
  }

  .wishlist .product-item-inner {
    padding: 8px;
  }

  .block-my-wish .price {
    @extend .f14;
  }

  .wishlist .product-reviews-summary,
  .wishlist .comment-box {
    display: none;
  }

  .wishlist .product-item-name {
    text-align: center;
    padding: 0 10px;
    padding-top: 14px;
    font-size: 12px;
  }

  .products-grid .product-item-inner {
    margin: 0 0 0 -1px;
  }

  .box-address-list {
    float: left;
  }

  .box-billing-address .box-content,
  .box-shipping-address .box-content,
  .box-address-list .box-content {
    border: 1px dashed #999;
    padding: 12px 8px 8px 8px;
    font-size: 14px;
  }

  .box .box-title {
    display: block !important;
  }

  .box .box-title span {
    @extend .f14;
    font-weight: bold;
    font-size: 14px !important;
  }

  .box-info {
    width: 100% !important;
  }

  .form-edit-account .fieldset.password {
    display: none !important;
  }

  .form-edit-account .field input[type='text'],
  .form-edit-account .field input[type='email'],
  .form-edit-account .field input[type='tel'] {
    @include input-padrao($light-gray, $black-80, true);
  }

  .form-address-edit .field input[type='text'],
  .form-address-edit .field input[type='email'],
  .form-edit-account .field input[type='tel'] {
    @include input-padrao($light-gray, $black-80, true);
    margin-top: 5px;
  }

  .form-address-edit .street label {
    display: none;
  }

  .field-name-firstname,
  .field-name-lastname {
    margin-bottom: 5px;
    margin-top: 5px;
  }

  .address-edit {
    font-size: 12px;
    line-height: 18px;
  }

  .form-edit-account .fieldset > .field,
  .form-edit-account .fieldset > .fields > .field {
    margin: 0 0 5px;
  }

  .form-edit-account .taxvat {
    clear: both;
  }

  .form-edit-account .form-edit-account .actions-toolbar .primary {
    float: right;
    margin: 0;
  }

  .form-edit-account .form-newsletter-manage .fieldset {
    margin: 0;
  }

  .form-newsletter-manage fieldset {
    margin: 0;
  }

  .form-edit-account .form-newsletter-manage .fieldset .actions-toolbar {
    display: none;
  }

  .form-edit-account .box-newsletter {
    padding: 0;
  }

  .form-edit-account .box-billing-address input,
  .form-edit-account .box-shipping-address input {
    margin-top: 10px;
  }

  .no-edit .btn-edit {
    float: right;
  }

  .block-dashboard-addresses select,
  .block-dashboard-addresses .actions-toolbar {
    margin-top: 10px;
  }

  .click-edit:hover,
  .click-delete:hover {
    cursor: pointer;
  }

  .click-edit {
    float: right;
    position: relative;
    top: 24px;
    right: -6px;
    @extend .f14;
    padding: 10px;
  }

  .click-delete {
    float: right;
    position: relative;
    top: 24.5px;
    right: -26px;
    font-size: 13px;
    padding: 8px;
    color: #4d4d4d;
  }

  a.click-delete:visited {
    color: #4d4d4d;
  }

  .box-address-list .click-edit {
    float: right;
    position: relative;
    top: 28px;
    right: 24px;
    @extend .f14;
    padding: 8px;
  }

  .block-dashboard-addresses {
    margin-bottom: 0 !important;
  }

  .block-dashboard-addresses .field-name-firstname .label,
  .block-dashboard-addresses .field-name-lastname .label {
    display: none;
  }

  .block-dashboard-info .e-button,
  .form-address-edit .e-button {
    font-weight: normal;
    margin-right: 0 !important;
    @extend .f14;
  }

  .form-edit-account .actions-toolbar .primary {
    float: right;
  }

  .block-dashboard-addresses .actions-toolbar .primary {
    float: right;
  }

  .form-newsletter-manage .actions-toolbar {
    float: right;
  }

  .box-newsletter .actions-toolbar, .box-info .actions-toolbar {
    padding-top: 8px;
  }

  .form-newsletter-manage {
    width: 90%;
  }

  .e-button {
    font-weight: normal;
    @extend .f14;
    @include botao-padrao($black, $white, false);
  }

  .e-button:hover {
    background: #c1c1c1;
    border: 0;
  }

  .clearboth {
    clear: both;
  }

  .box-address-list .box {
    width: 100% !important;
  }

  .block-addresses-list address {
    font-size: 14px;
  }

  #address-list .form-address-edit .field-name-firstname,
  #address-list .form-address-edit .field-name-lastname {
    margin-top: 4px;
  }

  #address-list address {
    margin-top: 4px;
  }

  #address-list address p {
    line-height: 16px;
    font-size: 12px;
  }

  #address-list .box-address-list:nth-child(even) .block-content {
    padding-right: 0;
  }

  .block-my-avalhacoes .info, .block-my-credits .info, .block-my-assinaturas .info {
    width: 90%;
    background: transparent;
    color: #000;
    text-align: left;
  }

  #add-address {
    position: relative;
    background-color: #eeeeee;
    padding: 6px;
    border-radius: 4px;
    padding-bottom: 12px;
    margin-top: 15px;

    .wcoll {
      padding: 8px;
      box-sizing: border-box;
    }

    .field {
      margin-bottom: 10px;
    }

    #street_3 {
      width: 90%;
    }

    .titulo {
      font-size: 18px;
      padding-left: 8px;
      margin-top: 8px;
      display: block;
      margin-bottom: 8px;
    }

    #street_1 {
      margin-bottom: 10px;
    }

    select,
    input {
      -webkit-box-shadow: 1px 0px 5px 0px rgba(176, 176, 176, 0.6);
      -moz-box-shadow: 1px 0px 5px 0px rgba(176, 176, 176, 0.6);
      box-shadow: 1px 0px 5px 0px rgba(176, 176, 176, 0.6);
      border: 0;
      border-radius: 3px;
    }
  }

  .block-addresses-default hr {
    border-top: 1px dashed;
  }

  .form-address-add .field input[type='text'],
  .form-address-add .field input[type='email'] {
    background-color: #fff;
  }

  .form-address-add .nested .label {
    display: contents;
  }

  .form-address-add .actions-toolbar .primary {
    display: block;
  }

  #address-list .empty {
    text-align: center;
    padding: 10px 0;
  }

  .form-address-add .actions-toolbar .primary {
    width: 98%;
    margin: 0 auto;
    float: none;
  }

  #meus-pedidos ul {
    padding: 0;
    margin-top: 28px;
  }

  #meus-pedidos ul li {
    list-style: none;
    background-color: #eee;
    padding: 6px 0;
    margin: 12px 0;
    line-height: 30px;
    border-radius: 4px;
    font-size: 12px;
  }

  #meus-pedidos ul li p:first-child {
    padding-left: 16px;
    box-sizing: content-box;
    width: calc(20% - 16px);
  }

  #meus-pedidos ul li p {
    float: left;
    text-align: center;
    width: 20%;
    margin: 0;
  }

  #meus-pedidos iframe {
    width: 100%;
    border: 0;
    height: 1000px;
  }

  .my-orders-table {
    position: relative;
  }

  .my-orders-table [aria-expanded='false']::after {
    top: 10px;
    right: 10px;
  }

  .my-orders-table [aria-expanded='true']::after {
    top: 10px;
    right: 10px;
  }

  .message.success {
    display: table;
    margin: 5px auto;
    border-radius: 6px;
    background-color: #dff2bf;
    color: #548c14;
  }

  .message.success > *:first-child:before {
    color: #548c14;
  }

  .message.error {
    display: table;
    margin: 5px auto;
    border-radius: 6px;
  }

  .set-end {
    margin-left: 8px;
    display: inline-block;
  }

  .mobon {
    display: none;
  }

  .cartoes-salvos {
    padding: 0;
  }

  .cartoes-salvos-title {
    line-height: 1px;
    border-radius: 8px;
    font-size: 12px;
    margin-bottom: 20px;
  }

  .cartoes-salvos-title p {
    float: left;
    width: 25%;
    text-align: center;
  }

  .cartoes-salvos li img {
    position: relative;
    top: 7px;
  }

  .cartoes-salvos li {
    line-height: 1px;
    background: #eee;
    border-radius: 4px;
    height: 38px;
    line-height: 38px;
    font-size: 12px;
    clear: both;
  }

  .cartoes-salvos li p {
    float: left;
    width: 25%;
    padding: 0;
    text-align: center;
  }

  .cartoes-salvos li p strong {
    display: none;
  }

  .form-address-edit .company .label {
    display: none;
  }

  .amreturns-order-select {
    background: #eee;
    border: 0;
    margin-bottom: 10px;
  }

  .block-my-trades .notrade {
    text-align: center;
    padding: 25px 0;
  }

  .amreturns-order-select select {
    background: #fff;
  }

  .products-grid .product-item-info:hover,
  .products-grid .product-item-info.active {
    border: 0;
    padding: 0;
    margin: 0;
    background: #fff;
    box-shadow: none;
  }

  .amreturns-select-title {
    font-size: 12px;
    font-weight: normal;
  }

  .amreturns-orders-container .amreturn-btn {
    font-size: 12px;
  }

  .amreturns-orders-container select {
    border: 0;
    border-radius: 4px;
  }

  .amreturn-btn,
  .amreturn-btn:hover {
    @include botao-padrao($black, $white, false);
  }

  .products-grid.wishlist .product-item-photo {
    display: table;
    margin: 0 auto;
  }

  .products-grid.wishlist .product-item {
    padding: 0;
    margin: 0;
  }

  .e-link:hover {
    border: 0;
    background: none;
  }

  .e-link .fa {
    padding-left: 8px;
  }

  .table-reviews {
    font-size: 12px;
  }

  .wishlist-toolbar,
  .products-reviews-toolbar {
    @extend .f14;
    margin: 0;
  }

  .rating-summary .rating-result > span:before {
    color: #333333;
  }

  .e-link {
    box-shadow: none !important;
    @extend .f14;
    text-decoration: underline;
    background: none;
    border: 0;
  }

  .block-reviews-dashboard {
    display: none;
  }

  .form-wishlist-items, .block-my-notes {
    .message.info {
      background: none;
      color: $black;
    }
  }

  @media only screen and (min-width: 1024px) {
    .form-wishlist-items .slick-track {
      width: 100% !important;
    }

    #minhaconta .wishlist .product-item {
      width: 100% !important;
      float: left;
    }
  }

  @media only screen and (max-width: 1024px) {
    .products-grid.wishlist .product-item-photo {
      display: table;
      margin: 0 auto;
    }
    .block-my-wish .products-grid .product-item-info:hover,
    .block-my-wish .products-grid .product-item-info.active,
    .block-my-wish .products-grid .product-item-inner {
      background-color: #fff;
    }
    .products-grid.wishlist .product-item .box-tocart {
      margin-right: 0;
    }
    .wishlist .product-item-name,
    .wishlist .price-box {
      margin-left: 0 !important;
    }
    .products-grid.wishlist .product-item-actions,
    .products-grid.wishlist .product-item-photo,
    .products-grid.wishlist .product-item .box-tocart {
      float: none;
    }
    .cartoes-salvos li p strong {
      display: inline-block;
    }
    .cartoes-salvos-title {
      display: none;
    }
    .cartoes-salvos li {
      height: auto;
      padding: 15px 15px 5px 15px;
      position: relative;
    }
    .delete-icon {
      position: absolute;
      top: 2px;
      right: 10px;
      font-size: 14px;
    }
    .cartoes-salvos li p {
      width: 100%;
      float: none;
      text-align: left;
      line-height: 25px;
    }
    .cartoes-salvos li p img {
      width: 28px;
      height: 20px;
      margin-left: 2px;
    }
    .mobon {
      display: block;
    }
    .moboff {
      display: none;
    }
    .form-newsletter-manage {
      width: 100%;
    }
    .box-newsletter {
      margin-top: 60px;
      display: block;
    }
    .form-edit-account .actions-toolbar .primary {
      float: none;
      display: table;
      margin: 0 auto;
    }
    .box-info .actions-toolbar,
    .form-newsletter-manage .actions-toolbar {
      float: none;
      display: block;
      margin: 0 auto;
    }
    html,
    body {
      margin: 0;
      height: 100%;
      overflow-x: hidden;
    }
    #minhaconta {
      width: 92%;
      display: block;
      margin: 0 auto;
    }
    .form-address-add .actions-toolbar .primary {
      width: 96%;
    }
    #add-address {
      width: 96%;
    }
    .form-address-edit {
      padding-bottom: 42px;
    }
    #meus-pedidos ul li p:first-child {
      padding-left: 0;
      box-sizing: content-box;
      width: 100%;
    }
    #meus-pedidos ul li p {
      width: 100%;
    }
    .block-dashboard-info .block-content,
    #add-address {
      display: table;
    }
    .change-default,
    .change-default:hover {
      padding: 10px;
    }
    .w-50 {
      width: 100% !important;
    }
    #minhaconta .form-edit-account,
    #add-address #street_3 {
      width: 100%;
    }
    #minhaconta .box-newsletter {
      text-align: center;
    }
    #minhaconta .block-dashboard-addresses .block-content {
      width: 90%;
    }
    #add-address {
      padding-bottom: 12px;
    }
    .amreturns-order-select .amreturn-select,
    .amrma-tbody {
      font-size: 12px !important;
    }
    .amrma-tbody p {
      margin-top: 8px;
    }
  }

  .amrma-row {
    padding-bottom: 20px;
  }
}


.amgdpr-checkbox-container > .amgdpr-label {
  background-color: #FAFAFA;
  border: 2px solid #f1f1f1;
}

.amgdpr-checkbox-container > .amgdpr-label:before {
  filter: grayscale(100%);
}


.amgdpr-checkbox-container > .amgdpr-checkbox:focus + .amgdpr-label, .amgdpr-checkbox-container > .amgdpr-checkbox:active + .amgdpr-label, .amgdpr-checkbox-container > .amgdpr-label:hover {
  border: 2px solid #bdbdbd;
  box-shadow: 0 5px 10px #c1c1c1;
}


.amgdpr-modal-container .action.action-primary {
  background-color: #7a7a7a;
}

.amgdpr-modal-container .action.action-primary:hover {
  background-color: #3c3c3c;
}

.amgdpr-modal-container .modal-header .modal-title {
  color: #2a2a2a;
}


.amgdpr-checkbox-container > .amgdpr-checkbox:checked + .amgdpr-label {
  background-color: #919191;
}