.amsearch-autocomplete-image video {
    display:none;
}

.eth-video {
    .eth_play{
        display: inline-block;
        border-radius: 150%;
        padding-top:10px;
    }
    
    .video-gallery{
        cursor:pointer;
    }
}

.produto-com-video img, .produto-com-video .product-image-wrapper{
    display: none;
}

.produto-com-video .product-image-container {
    display: block;
}

.page-layout-1column {
    .eth-video {
        text-align: center;
        margin-bottom: 10px;
        float: left;
        width: 130px;
        height: 100px;
        line-height: 170px;
        height: 130px;
    }
}

.page-layout-modelo-full {
    .eth-video {
        text-align: center;
        width: 100%;
    }
}

.amasty-gallery-image {
    display: block;
    margin: 0 auto;
}


@media (max-width: 1023px) {
    .eth-video {
        margin-bottom: -20px !important;
    }
}