.sem-stock-geral {
    //background: linear-gradient(to left top,rgba(255,255,255,0) 0%,rgba(255,255,255,0) 42%,rgba(255,255,255,1) 35%,rgba(255,255,255,1) 46%,rgba(90,90,90,0.5) 50%,rgba(255,255,255,0) 53%,rgba(255,255,255,0) 100%,rgba(255,255,255,0) 57%,rgba(255,255,255,0) 100%,rgba(255,255,255,0) 100%) !important;
    border: 1px solid #ccc;
}

.swatch-option.sem-stock:after, .swatch-option.sem-stock-geral:after {
    background: linear-gradient(to left top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 42%, #fff0 30%, #fff0 49%, #b5b5b5 47%, #b5b5b5 53%, #fff0 54%, #fff0 57%, rgba(255, 255, 255, 0) 58%, rgba(255, 255, 255, 0) 100%);
    background: -moz-linear-gradient(to left top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 42%, #fff0 30%, #fff0 49%, #b5b5b5 47%, #b5b5b5 53%, #fff0 54%, #fff0 57%, rgba(255, 255, 255, 0) 58%, rgba(255, 255, 255, 0) 100%);
    background: -ms-linear-gradient(to left top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 42%, #fff0 30%, #fff0 49%, #b5b5b5 47%, #b5b5b5 53%, #fff0 54%, #fff0 57%, rgba(255, 255, 255, 0) 58%, rgba(255, 255, 255, 0) 100%);
    background: -o-linear-gradient(to left top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 42%, #fff0 30%, #fff0 49%, #b5b5b5 47%, #b5b5b5 53%, #fff0 54%, #fff0 57%, rgba(255, 255, 255, 0) 58%, rgba(255, 255, 255, 0) 100%);
    background: -webkit-gradient(linear, left top, right bottom, color-stop(0%, rgba(255, 255, 255, 0)), color-stop(42%, rgba(255, 255, 255, 0)), color-stop(30%, #fff0), color-stop(49%, #fff0), color-stop(47%, #b5b5b5), color-stop(53%, #b5b5b5), color-stop(54%, #fff0), color-stop(57%, #fff0), color-stop(58%, rgba(255, 255, 255, 0)), color-stop(100%, rgba(255, 255, 255, 0)));
    background: -webkit-linear-gradient(to left top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 42%, #fff0 30%, #fff0 49%, #b5b5b5 47%, #b5b5b5 53%, #fff0 54%, #fff0 57%, rgba(255, 255, 255, 0) 58%, rgba(255, 255, 255, 0) 100%);
    bottom: 0;
    content: '';
    filter: 'progid:DXImageTransform.Microsoft.gradient(startColorstr=#00ffffff, endColorstr=#00ffffff, GradientType=1)';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
}

.sem-stock.selected, .sem-stock-geral.selected {
	outline: 1px solid #777;
}
