.lista-de-cupom {
    .cupom-item{
        z-index: inherit;
        list-style: none;
        border-radius: 3px;
        background-color: $green;
        color: $white;
        padding: 7px 14px;
        float: left;
        cursor: pointer;
        margin-right: 10px !important;
        @extend .f12;
        span:before {
            color: $white;
            @extend .f8;
            content: '\e616';
            font-family: 'luma-icons';
        }  
    }
}