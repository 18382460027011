.catalog-product-view {
    @import '../componentes/swatch-opt';

    .field.qty,
    .amrelated-discount.-product,
    .product-info-stock-sku {
        display: none;
    }

    .slick-slider {
        padding: 0;
    }
    
    .amrelated-content-wrapper .amrelated-summary-content{
        width: 250px;
    }

    #amasty-main-container .slick-dots {
        position: relative;
    }

    .ndd-uberzoom-fullscreen {
        margin: -32px 0px 0px -32px !important;
        width: 19px !important;
        height: 19px !important;
        background: rgba(0,0,0,0.20);
    }

    .ndd-uberzoom-interface {
        opacity: 1 !important;
    }

    .ndd-uberzoom-fullscreen img{
        margin: 2px;
        width: 15px !important;
        height: 15px;
    }

    .video-thumb-icon {
        text-align: center;
        pointer-events: none;
    }
    
    .mgz-fa-play-circle:before {
        color: black;
        border: 1px solid #999;
        background: #fcfcfc;
        padding: 20px;
    }
    
    #amasty-main-container{
        padding: 0;
    }

    .mgz-fa-play-circle:hover:before {
        color: grey;
    }

    .product-info-main {
        margin: 0 auto;
        max-width: 500px;
        width: 100%;
        .page-title {
            @extend .f18;
            text-transform: uppercase;
        }

        .oldprice {
            padding: 0px;
        }

        .box-tocart {
            .action.tocart {
                @include botao-padrao($black, $white, true);
                width: calc(100%);
                float: left;
                position: relative;
                z-index: 1;
                margin-bottom: 10px;
            }
            .label {
                @extend .f10;
                float: left;
                margin-right: 10px;
                position: relative;
                top: 13px;
            }

            #qty {
                padding: 0;

                border: 1px solid $black-70;
            }
            .qty .label {
                text-transform: uppercase;
            }
        }

        .product-info-price {
            border: 0;
            display: inline-block;
            .widget {
                width: 130px;
                float: right;
                position: relative;
                top: 0;
            }

            .best-installment {
                .discounts {
                    ul {
                        margin: 0;
                    }
                }
            }
        }

        .product-social-links .action.mailto.friend.amconf-share-link {
            margin: 0;
        }

        .product-social-links {
            @extend .f12;
            color: $black;
            letter-spacing: 2px;
            position: relative;
        }

        .product-addto-links {
            display: none;
        }

        .product-social-links .action.mailto.friend.amconf-share-link {
            float: right;
        }

        .product.attribute.sku {
            margin-top: -22px;

            .type, .value {
                margin-top: 8px;
                @extend .f10;
                color: rgba(0, 0, 0, 0.4);
                text-transform: uppercase;
            }
        }

        .product-info-price {
            .price-box {
                .old-price {
                    text-decoration: none;
                    .price {
                        position: relative;
                        top: 5px;
                        margin-left: -20px;
                        left: 28px;
                        text-decoration: line-through;
                        @extend .f12;
                    }
                }
                .price {
                    @extend .f12;
                    margin: 0 auto;
                }

                margin-top: 8px;
            }
        }
    }

    .normal-price,
    .special-price {
        margin: 0 auto;
        order: 0;
        padding-left: 0;
    }

    .eth_installments {
        position: relative;
        top: 32px;
        @extend .f18;
    }

    .product-options-wrapper {
        .fieldset {
            margin-top: 0;
        }
    }

    .products-grid .swatch-option {
        float: none;
        display: inline-block;
        border: 1px solid #ccc;
    }
    
    .products-grid .swatch-option.text {
        background: #fff;
        font-weight: 500;
        padding: 3px 5px;
    }


    .product.info.detailed {
        .product.data.items>.item.content {
            border: 0;
            @extend .f12;
            padding: 0;
        }

        .mgz-element-column > .mgz-element-inner {
            padding: 0;
        }

        .product.data.items>.item.title>.switch {
            @extend .f12;
            padding: 0;
            border-top: 0;
            border-right: 0;
            border-left: 0;
            margin-bottom: 16px;
            height: 35px;
            text-transform: uppercase;
            color: $black;
            border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        }
        .amtabs-accordion-view.product.data.items {
            border: 0;
        }
    }

    .product-info-main .product-add-form {
        padding-top: 0px;
    }

    @media screen and (max-width:1023px){
        #maincontent{
            padding: 0;
        }

        .product-info-main {
            width: initial;
        }

        .amrelated-grid-wrapper .slick-slide {
            margin: 0 !important;
        }

        .amrelated-summary-content .dizy-installment .best-installment {
            text-align: left;
        }

        .amrelated-grid-wrapper .widget-product-grid {
            display: block;
        }

        .catalog-product-view .product.media {
            margin-top: -50px;
        }

        .product-social-links .action.towishlist span {
            font-size: 12px;
        }

        .product-info-main .product-social-links .action.mailto.friend.amconf-share-link {
            font-size: 12px;
            float: none !important;
            margin-top: 12px !important;
        }
        .product.data.items {
            margin: 0;
        }
        .product-info-main .page-title {
            margin-top: 36px;
        }
    }
    .product-info-main .product.attribute.overview {
        display: none;
    }
}

.attr-img img{
    max-width: 60px;
}

.mgz-fa-play-circle:before {
    content: "\f144";
    margin: 0 auto;
    display: table;
  }

  @media only screen and (max-width : 1024px) {
/*         #amasty-main-image {
      display: none;
    } */



    #amasty-gallery-images {
      padding: 0;
    }

    #amasty-main-video {
      width: 100%;
      margin: 0 auto;
      display: block;
    }

    #amasty-gallery-images:not(.slick-vertical) .slick-list {
      margin: 0;
    }

    #amasty-main-container {
      height: 100%;
      width: 100%;
    }

    .slick-slide img {
      width: 100% !important;
    }

    .slick-slider .slick-arrow {
      z-index: 3;
    }

    #amasty-gallery-container {
      z-index: 2;
      position: relative;
    }

    #amasty-gallery {
      position: relative;
    }

    #amasty-gallery-container .slick-dots {
      bottom: -25px;
    }

  }

  #amasty-main-video {
    margin: 0 auto;
  }

  @media only screen and (min-width : 1025px) {
    #amasty-gallery-container .slick-dots {
      display: none !important;
    }
  }

