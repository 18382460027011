.amasty_rma-account-newreturn, .amasty_rma-account-view {
	background: #eee;
	
	.mc-title {
		text-align: center;
		padding-top: 55px;
		font-size: 28px;
		letter-spacing: 4px;
		padding-bottom: 20px;
	}
	
	.mc-box .border {
		border: 1px dashed #999;
		padding: 12px 8px 8px 8px;
	}
	
	.mc-box {
		box-sizing: content-box;
		font-size: 12px;
	}
	
	.mc-form label {
		font-size: 12px;
	}
	
	.mc-form input {
		background-color: #EEE;
		border-radius: 4px;
		border: 0;
		line-height: 30px;
		font-size: 12px;
		height: 40px;
	}
	
	.mc-form select {
		font-size: 12px;
		background: #eee;
		border: 0;
		height: 40px;
		border-radius: 4px;
	}
	
	.mc-form textarea {
		font-size: 12px;
		background: #eee;
		border: 0;
		border-radius: 4px;
	}
	
	.mc-box .title {
		font-size: 14px;
	}
	
	.amrma-message-container input,
	.amrma-message-container select {
		background: #fff;
		height: 30px;
		line-height: 24px;
		font-size: 12px !important;
	}
	
	.mc-page {
		max-width: 760px;
		margin: 0 auto;
		background: #fff;
		border-radius: 8px;
		-webkit-box-shadow: 1px 0px 5px 0px rgba(176, 176, 176, 0.6);
		-moz-box-shadow: 1px 0px 5px 0px rgba(176, 176, 176, 0.6);
		box-shadow: 1px 0px 5px 0px rgba(176, 176, 176, 0.6);
		padding: 24px;
	}
	
	.amrma-create-rma {
		max-width: 760px;
		margin: 0 auto;
		background: #fff;
		border-radius: 8px;
		-webkit-box-shadow: 1px 0px 5px 0px rgba(176, 176, 176, 0.6);
		-moz-box-shadow: 1px 0px 5px 0px rgba(176, 176, 176, 0.6);
		box-shadow: 1px 0px 5px 0px rgba(176, 176, 176, 0.6);
		padding: 24px;
	}
	
	.amrma-img-text {
		font-size: 14px;
		background: #fff;
		padding: 0;
		margin-top: -14px;
		margin-bottom: 8px;
	}
	
	.amrma-bank-details label {
		font-size: 10px !important;
	}
	
	.amrma-bank-details {
		max-width: 500px;
	}
	
	.amrma-bank-details .amrma-input-block {
		float: left;
		width: calc(33.333333% - 16px);
		margin-right: 16px;
	}
	
	.amrma-image {
		width: 140px;
		height: 160px;
	}
	
	.amrma-product-information {
		font-size: 12px;
	}
	
	.amrma-product-information strong {
	}
	
	.amrma-item-name {
		font-size: 14px;
	}
	
	.amrma-product-information p {
		padding: 2px 0;
	}
	
	.amrma-title {
		font-size: 20px;
		padding-bottom: 12px;
	}
	.mc-title .amrma-status {
		padding: 10px;
		position: relative;
		top: -5px;
	}
	
	.amrma-info {
		margin-bottom: 0;
	}
	
	
	.amrma-customer-address .border {
		line-height: 20px;
	}
	
	.amrma-return-settings-container {
		background: #eee;
		border-radius: 4px;
		margin: 0;
		max-width: 250px;
	}
	
	.amrma-return-select .amrma-title,
	.amrma-return-qty .amrma-label {
		margin-bottom: 0;
		padding-bottom: 0;
		font-size: 11px;
		position: relative;
		top: 5px;
	}
	
	.amrma-message-container.-options {
		border: 0;
	}
	
	#reembolso {
		font-size: 14px;
	}
	
	#reembolso ul {
		padding: 0;
	}
	
	#reembolso ul li {
		width: 100%;
	}
	
	#reembolso ul li>.box {
		display: inline-block;
		padding: 0 8px;
	}
	
	#reembolso input[type="radio"] {
		float: left;
		position: relative;
		top: 8px;
	}
	
	.amrma-cancel {
		text-align: center;
		margin: 0 auto;
	}
	

	.amrma-cancel {
		margin-top: 12px;
	}

	.e-button, .amrma-btns-block button {
		font-weight: normal;
		@extend .f14;
		@include botao-padrao($black, $white, false);
	}
	
	.amrma-attach-file .amrma-label, .amrma-link-cancel {
		color: $black !important;
	}
	
	.amrma-step {
		z-index: 5;
		display: block !important;
	}
	
	.amrma-steps .-checked i {
		border: 3px solid #0E9E58;
	}
	
	.amrma-step i {
		width: 64px;
		height: 64px;
		font-size: 24px;
		margin-bottom: 8px;
		background-color: #eee;
		text-align: center;
		line-height: 64px;
		border-radius: 50%;
	}
	
	.amrma-step::before {
		display: none;
	}
	
	.amrma-step::after {
		margin-top: 50px;
		margin-left: 20px;
	}
	
	.amrma-bank-details {
		font-size: 12px;
	}
	
	.amrma-bank-details p {
		margin-top: 8px;
	}
	
	.amrma-customer-information {
		padding-top: 50px;
	}
	
	.amrma-hr {
		position: relative;
		max-width: 600px;
		top: -15px;
		border: #eee 1px solid;
	}
	
	.amrma-customer-information p {
		margin-top: 8px;
	}
	
	.table-returns-items .details,
	.amrma-chat-block {
		font-size: 12px;
	}
	
	@media only screen and (max-width: 1024px) {
		.mc-page {
			margin: 0 12px;
		}
		.amrma-name {
			font-size: 12px !important;
		}
		.amrma-step i {
			width: 42px;
			height: 42px;
			font-size: 16px;
			margin-bottom: 8px;
			background-color: #eee;
			text-align: center;
			line-height: 42px;
			border-radius: 50%;
			margin: 0 auto;
			display: block
		}
		.amrma-hr {
			top: -30px;
			width: 84%;
			left: -10px;
		}
		.amrma-name {
			white-space: nowrap;
			font-size: 20px;
		}
		.amrma-steps .-checked i {
			border: 2px solid #0E9E58;
		}
		.amrma-step {
			width: 100%;
		}
		.account .table-wrapper {
			border: 0;
		}
		.amreturns-order-select .amreturn-select,
		.amrma-tbody {
			font-size: 12px !important;
		}
		.amrma-tbody p {
			margin-top: 8px;
		}
	}
	
	.amrma-row {
		padding-bottom: 20px;
	}
	
	.amrma-table-container .table-wrapper>.amrma-table .amrma-tbody>.amrma-row>.col:first-child {
		left: 0;
	}
	
	
}

.amasty_rma-account-newreturn .amrma-table-container .table-wrapper>.amrma-table .amrma-tbody>.amrma-row>.col:not(:first-child) {
	padding-left: 100px;
}