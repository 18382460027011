// ------------------------------------------------------------------------------
// TYPOGRAPHY
// ------------------------------------------------------------------------------

/* Sizes */

.f40 {font-size: rem($font-size-40);}
.f30 {font-size: rem($font-size-30);}
.f26 {font-size: rem($font-size-26);}
.f24 {font-size: rem($font-size-24);}
.f22 {font-size: rem($font-size-22);}
.f20 {font-size: rem($font-size-20);}
.f18 {font-size: rem($font-size-18);}
.f16 {font-size: rem($font-size-16);}
.f14 {font-size: rem($font-size-14);}
.f12 {font-size: rem($font-size-12);}
.f10 {font-size: rem($font-size-10);}
.f8 {font-size: rem($font-size-8);}
