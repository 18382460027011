header {
  .cart-summary {
    width: inherit;
    position: fixed;
  }
}

.cart-summary {
  @import 'codeseller';
}

#co-shipping-method-form {
  .items.methods{
    overflow: auto;
    max-height: 78px;
    font-size: 12px;
  }
}

.minicart-wrapper .total-items .tooltiptext td{
  padding: 5px;
}

#block-summary input[type="text"], #block-summary input[type="tel"]{
  @include input-padrao($white, $black-80, false, 75px);
  float: left;
}

.cart-summary {
  width: 390px;
  padding: 0;
  background-color: #fff;
  bottom: 0;
  position: fixed;
  right: 0;
  top: initial !important;


  .checkout-methods-items .action.primary.checkout{
    font-weight: normal;
    @include botao-padrao($black, $white, false);
    float: left;
  }

  .cart-totals {
    padding: 9px 0 27px 0;
  }

  input[type="radio"] {
    //@import '../uikit/radio-padrao';
    display: none;
  }


  #co-shipping-method-form {
    .field {
      font-size: 10px;
      margin: 0;
      padding: 0;
      padding-bottom: 8px;
    }
  }

  .cart-totals {
    border-top: 0;
  }

  #block-shipping {
    @import '../uikit/radio-padrao';

    input[type="radio"]{
      position: relative;
      top: -4px;
    }

    position: relative;
    #shipping-zip-form {

      .field {
        margin: 0 0 15px;
      }

      button {
        @include botao-padrao($black, $white, false);
        font-size: 14px;
        float: right;
        height: 45px;
        font-weight: 0;
        margin-top: -15px;
      }
      input {
        width: calc(100% - 83px);
      }
      a {
        position: relative;
        top: -8px;
        @extend .f10;
        color: $black-80;
        text-decoration: underline;
      }
    }
    .label {
      color: $black-80;
      @extend .f14;
    }
    .item-options {
      margin: 0;
    }
    .item-title span {
      @extend .f12;
      color: $black-80;
    }
    padding-bottom: 12px;
    a {
      @extend .f12;
    }
  }

  .checkout-methods-items {
    padding-top: 40px !important;
    position: fixed;
    bottom: 0px;
    width: 90%;
    padding-bottom: 20px !important;
    background-color: $white;
    z-index: 99;
  }

  #shipping-zip-form input{
    width: 100%;
    margin-bottom: 10px;
  }

  #block-shipping {
    background-color: $light-gray;
    padding: 0 15px;
    border-radius: 1px;
    margin: 7px 0 9px 0;
    .title {
      border-top: 0;
      color: $black-80;
      strong {
        @extend .f12;
        font-weight: normal;
      }
    }
    .content {
      padding-bottom: 1px;
    }
    .item-title {
      font-weight: normal;
    }
    .fieldset {
      margin: 0;
    }
    .field[name="shippingAddress.postcode"]{
      .label{
        display: none;
      }
    }

    .field[name="shippingAddress.country_id"], .field[name="shippingAddress.region_id"]{
      display: none;
    }
  }
}

.cart-summary .block .fieldset .field {
  margin: 0 0 7px;
}

@media only screen and (max-width: 1024px) {

  .amwrap-gift-button {
    top: 0 !important;
  }

  .minicart-wrapper .copy-cart {
    right: 54px !important;
  }
  .cart-summary #block-shipping {
    margin: 7px 0px 9px 0px;
  }
}
@media only screen and (max-width: 360px) {
  .copy-cart {
    width: 200px;
    left: 75px;
  }
  .cart-summary .checkout-methods-items .action.primary.checkout {
    margin-left: 2% !important;
  }
}
