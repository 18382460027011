.progress-bar-wrap {
    width: 90%;
    display: block;
    margin: 0 auto;

    label {
        display: block;
        margin-top: 4px;
        font-size: 12px;
    }

    .carrier-icon {
        top: 2px;
        transition:2s;
        position: absolute;
    }

    progress {
        width: 100%;
        border-radius: 7px;
        height: 12px;
        box-shadow: 1px 1px 4px rgba( 0, 0, 0, 0.2 );
        z-index: -1;
        position: relative;
      }
      progress::-webkit-progress-bar {
        background-color: rgb(239, 239, 239);
        border-radius: 2px;
      }
      progress::-webkit-progress-value {
        background-color: rgb(0, 255, 92);
        border-radius: 2px;
      }

      #progressive {
          position: absolute;
          width: 300px;
          height: 100px;
          z-index: 15;
          top: 50%;
          left: 50%;
          margin: -100px 0 0 -150px;
      }
      .progress {
          position: relative;
          height: 14px;
          display: block;
          width: 100%;
          background-color: #16da72;
          border-radius: 20px;
          margin: 0.5rem 0 1rem 0;
          overflow: hidden;
      }

      .proginput {
          height: 25px;
          position: relative;
      }

      .carrier-icon {
          z-index: 3;
          top: 0 !important;
          margin-left: -14px;
      }

      .progress-bar {
          position: absolute !important;
          top: 0;
          right: 0;
          bottom: 0;
          float: left;
          height: 100%;
          background-color: #f5f5f5;
          position: relative;
      }
}

