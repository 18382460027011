.minicart-wrapper {
  .codeseller-box{
    margin-bottom: 6px;

    .errors p {
      float: left;
    }
  }

  .cart-header{
    margin-bottom: 5px;
  }
  .miniproduct {
    margin-left: 75px;
    position: relative;
    top: 6px;
    z-index: 999;
    display: none;
    a {
      color: #000;
    }
  }

  #discount-coupon-form {
    .coupon_error {
      float: left;
    }
  }

  .abs-discount-block .block .content,
  .cart-summary .block .content,
  .paypal-review-discount .block .content,
  .cart-discount .block .content {
    display: none;
    padding: 0;
    position: relative;
  }

  #co-shipping-method-form .items.methods {
    overflow: auto;
    max-height: 78px;
    font-size: 12px;
    margin-top: 5px;
  }

  .items.methods p,
  #co-shipping-method-form label,
  #co-shipping-method-form span,
  main .cart-summary #block-shipping .label{
    padding: 0 0 5px;
    border-bottom: 1px dotted #CCC;
  }

  .codeseller-box,
  #block-discount {
    padding: 0;
  }

  #block-discount {
    button {
      font-weight: 400 !important;
      width: 80px;
      font-weight: 400;
      padding: 10px 15px;
      border-radius: 3px;
      color: #000;
      background: #fff;
      border: 1px solid #000;
      font-size: 14px;
      float: right;
      height: 45px;
      margin: 0 0 7px;
    }
  }

  .cart-summary .checkout-methods-items .action.primary.checkout {
    height: 45px;
    font-weight: 400 !important;
    border-radius: 3px;
    color: #fff;
    background: forestgreen;
    border: 0;
    display: block;
    margin: 6px auto;
    text-align: center;
    width: 100%;
  }

  .progress-bar-wrap .progress {
    position: relative;
    height: 14px;
    display: block;
    width: 100%;
    background-color: lightblue;
    border-radius: 20px;
    margin: 0.5rem 0 1rem;
    overflow: hidden;
  }

  .block-minicart .cart-summary .codeseller-box button,
  .block-minicart .cart-summary .cart-summary #block-discount button {
    font-weight: 400 !important;
    width: 80px;
    font-weight: 400;
    padding: 10px 15px;
    border-radius: 3px;
    color: #000;
    background: #fff;
    border: 1px solid #000;
    font-size: 14px;
    float: right;
    height: 45px;
    margin-top: -45px;
  }

  .cart-summary {
    padding: 0 15px;
    #block-shipping {
      padding: 5px 15px;
      border-top: 3px solid #fff;
    
      #shipping-zip-form button {
        padding: 10px 14px;
        font-weight: 400 !important;
        border-radius: 3px;
        color: #000;
        background: #fff;
        border: 1px solid #555;
        font-size: 14px;
        float: right;
        height: 45px;
        font-weight: 0;
      }
    }
  }

  .product-item-details .price,
  .product-item-details .label,
  .product-item-details .values span {
    font-weight: 400;
    font-size: 12px;
  }

  input[type=text]:disabled,
  input[type=password]:disabled,
  input[type=url]:disabled,
  input[type=tel]:disabled,
  input[type=search]:disabled,
  input[type=number]:disabled,
  input[type=datetime]:disabled,
  input[type=email]:disabled {
    opacity: 1;
    background: #eee;
    font-size: 12px !important;
    border: none;
  }

  #block-discount input[type="text"] {
    width: calc(100% - 80px);
  }

  #minicart-content-wrapper{
    .block-content {
      overflow: auto;
      height: calc(100vh - 380px);
      /* width */
      &::-webkit-scrollbar {
        width: 8px;
        height: 30px;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #888;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
    }
  }

  .total-items{
    padding: 7px 0px;
    td{
      padding: 0;
    }
  }

  .mini-amount {
    text-align: right;
    font-weight: bold;
  }
  .tooltip{
    position: relative;
    display: inline-block;
    border-bottom: none;
    left: 10px;
    top: 6px;
  }
  .tooltip .tooltiptext {
    visibility: hidden;
    background-color: rgba(23, 23, 23, .85%);
    color: #fff;
    z-index: 99;
    border-radius: 3px;
    padding: 5px 0;
    position: fixed;
    bottom: 160px;
    right: 10px;
    display: inline-table;
    width: 100%;
    max-width: 350px;
    margin-left: 0;
    font-weight: bold;
    text-align: left;
  }

  .tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 89%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: rgba(23, 23, 23, .85%) transparent transparent transparent;
  }
  .tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }

  .product-bg-img{
    width: 75px;
    height: 75px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: left;
    float: left;
  }

  .no-stock {
    font-size: 10px;
    padding: 0;
    margin: 0;
    background: #ff5b5b;
    padding: 3px;
    display: inline-block;
    border-radius: 3px;
    color: #fff;
  }

  .swatch-option.text {
    background: 0 0;
    color: inherit;
    padding: 0;
    border: none;
    font-size: 12px;
    margin-top: -3px;
  }

  .codeseller-box {
    border-bottom: 1px solid $gray-ice;
  }

  .minicart-items-wrapper {
    border: 0;
    margin: 0;
    overflow: hidden;
    height: auto !important;
    padding: 0 15px 15px 15px;
  }

  .block-minicart .subtitle {
    display: none !important;
  }

  .copy-cart {
    margin: 12px auto !important;
    cursor: pointer;
  }

  @media only screen and (min-width: 1024px) {
    .copy-cart {
      padding-right: 17px;
    }
  }

  .copy-cart {
    text-align: center;

    i {
      @extend .f14;
      margin-right: 5px;
      position: relative;
      top: 1px;
    }
  }

  .amlabel-position-wrapper{
    z-index: 1 !important
  }

  .ui-dialog {
    z-index: 200;
    position: fixed;
    top: 0;
    right: 0;

    .block-minicart {
      z-index: 100;
      height: 100vh;
      max-width: 520px;
      margin-top: 0;
      padding: 0;
      border: 0;
      right: 0;
      overflow: auto;

      background: $white;
      /* width */
      &::-webkit-scrollbar {
        width: 8px;
        height: 30px;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #888;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }

      overflow: auto;
      margin: 0;
      border: 0;
      background-color: $white;
    }
  }

  .minicart-wrapper.active {
    z-index: 999999999;
  }

  .cart-header {
    padding: 0 20px;
    background-color: $black;
    height: 45px;
    line-height: 45px;
    color: $white;

    p {
      @extend .f16;
      @include icon(before, shopping-basket, false, 8px, 'right');
    }
  }

  .cart-content {
    .total {
      background-color: $white;
      @extend .f14;
      padding: 0 17px;

      span {
        position: relative;
        top: -2px;
        float: right;
        @extend .f20;
        color: $black-80;
      }

      @include clearfix();
    }

    .no-items {
      padding: 0 17px;
      position: relative !important;
      text-align: center;
      margin-top: 32px;
      margin-top: 18px;
      span {
        @extend .f16;
      }

      p {
        @extend .f14;
      }

      button {
        margin-top: 32px;
        font-weight: normal;
        @include botao-padrao($black, $white, true);
      }
    }
  }

  #top-cart-btn-checkout {
    height: 45px;
    font-weight: 400 !important;
    border-radius: 3px;
    color: #fff;
    background: forestgreen;
    border: 0;
    display: block;
    margin: 6px auto;
    text-align: center;
    width: 100%;
  }

  .cart-summary .block .fieldset .field{
    margin: 0 0 6px;
  }

  .compartilhar-skus {
    display: none;
  }

  body {
    background-color: red;
  }

  .viewcart {
    margin-top: 20px;
    display: block;
    @extend .f14;
    color: $black;
  }

  .minicart-items .action.delete {
    position: relative;
    top: 15px;
  }
  .product-item-details {
    position: relative;
    margin-left: 22px;
    padding: 0;

    dl {
      margin: 0;
    }

    dd {
      margin-bottom: 0;
    }

    .price, .label, .values span {
      font-weight: normal;
      @extend .f14;
    }

    @include clearfix;

    .product-item-name {
      padding-right: 44px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      text-align: left;
      margin: 0 0 8px;
      a {
        @extend .f14;
        font-weight: 900;
        color: #171717;
        font-size: 12px;
      }
    }
    .product-options {
      .label, .values {
        float: left;
      }
      .label {
        margin-right: 4px;
        font-size: 12px;
        margin-top: 1px;
      }
      .values {
        margin-right: 8px;
        img {
          max-width: 60px;
        }
      }
    }
  }

  .product-options .content{
    text-align: left;
  }

  .update-cart-item {
    float: none !important;
  }

  #mini-cart {
    .actions {
      position: absolute !important;
      top: -17px !important;
      margin: 0 !important;
      right: 0 !important;

      .primary {
        display: none
      }

      .delete:before {
        color: $black;
      }
    }
    .item {
      background: #eee;
      border-bottom: none;
      position: relative;
      padding: 7px 7px;
      margin-top: 0;
      margin-bottom: 7px;
    }
  }

  .price-wrapper {
    display: inline-block;
  }

  dl.product.options.list {
    color: $black;
  }

  .contador-de-quantidade {
    float: right;

    button {
      padding: 5px 15px;
      font-weight: 400 !important;
      border-radius: 3px;
      color: #555;
      background: none;
      border: 0;
      padding: 0;
      line-height: 23px;
      height: 24px;
      text-align: center;
      width: 25px;
      border: 1px solid #555;
    }

    .sub {
      position: relative;
      left: 3px;
      border-radius: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      background: #eee;
    }

    .add {
      position: relative;
      right: 4px;
      border-radius: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      background: #eee;
    }
  }

  .price-container {
    color: $black;
  }

  .product-item:not(:first-child) {
    border: 0;
  }

  #btn-minicart-close {
    margin-top: 8px;
    margin-right: 12px;
    color: $white;
    width: 155px;
    background: #2C2C2C;
    padding: 7px;
    &:before {
        font-family: sans-serif;
        color: $white;
        font-size: 12px;
        content: "continuar comprando";
    }
  }

  .clearfix {
    @include clearfix();
  }
}
.magezon-builder-preload .mgz-element {
  z-index: auto;
}

.block-search {
  z-index: 88 !important;
}

@media only screen and (max-width: 1024px) {
  #top-cart-btn-checkout {
    margin: 0 15px;
  }

  .minicart-wrapper .block-minicart {
      max-width: 100vw !important;
      width: 100vw;
  }

  header .cart-summary {
    width: 100vw;
  }

  header #block-discount .content {
    padding: 0;
  }
}

#empty-minicart {
    font-size: 12px;
    margin: 0 auto 10px auto;
    display: table;
    cursor: pointer;
    display: none;
}


.minicart-loader {
    position: absolute;
    z-index: 9999;
    background: #fff;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
}

.minicart-items-loader {
    position: absolute;
    z-index: 9999;
    background: #fff;
    top: 45px;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
}

.block-minicart{
    z-index: 9999;
}

.minicart-wrapper .cart-content .no-items{
    z-index: 99999;
    height: 100%;
    background-color: #fff;

}

.tooltiptext ul {
    padding: 8px;
}
.tooltiptext span {
    float: right;
}

.tooltip:hover{
    cursor: pointer;
}

.tooltiptext li {
    margin-bottom: 4px;
}
.minicart-wrapper {
    .product-options.content {
        display: flex;
    }
    .product-item-pricing {
        display: flex;
    }
}