@mixin botao-padrao($background, $textcolor, $fullWidth, $height: false) {
  @if $height {
    height: $height;
  }
  @else {
    padding: 10px 15px;
  }
  font-weight: normal !important;
  border-radius: 3px;
  color: $textcolor;
  @extend .f16;
  background: $background;
  border: 0;

  &:hover {
    cursor: pointer;
  }

  @if $fullWidth == true {
    display: block;
    width: 100%;
  }
}
