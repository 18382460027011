.loginCpf {
    .customer-account-login,.customer-account-create{
        textarea, select, input:focus, button { outline: none !important; }

        background:#f1f1f1 !important;
        .page-title{
            display:none !important;
        }
        .block-new-customer{
            display:none;
        }
    }
    .politica-privacidade-register{
        font-weight: normal!important;
        margin-bottom:10px;
    }
    .politica-privacidade-register label{
        @extend .f14;
    }
    .newsletter label{
        @extend .f14;
        }

    .politica-privacidade-register-link:visited{
        color: #464c52;
        text-decoration: underline;
        font-weight: bold;
       }
    .politica-privacidade-register-link{
        color: #464c52;
        text-decoration: underline;
        font-weight: bold;
    }
    .login-subtitle {
        text-align: center;
    }
    .block-authentication .block-customer-login:before{
        display: none !important;
    }
    .popup-authentication .block-authentication{
        border-top:0;
    }
    @media (min-width: 768px){
        .popup-authentication .modal-inner-wrap {
            min-width: 400px !important; 
            width: 500px;
        }
    }
    .popup-authentication .block-customer-login,.popup-authentication .block-new-customer{
        border: 1px solid #c1c1c1 !important;
    } 
     
    .customer-account-login .block-customer-login  ,.customer-account-create .block-customer-register,.block-authentication .block-customer-login , .block-authentication .block-new-customer{
            float:none !important;
            vertical-align: middle;
            margin: auto;

            padding:20px !important;
            .block-title{
                border:none;
                text-align: center;
                @extend .f40;
                margin-bottom: 15px;
                padding-bottom: 12px;
            }
        .form-create-account{
            width: 100% !important;
            min-width :100% !important;
        }
            .action.primary {
                @extend .f14;
                width: 100% !important;
                text-align: center;
                font-weight: normal;
                @include botao-padrao($black, $white, false, 45px);
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                line-height: initial;
            }
            .primary{
                width: 100%;
            }


            input[type="text"],input[type="email"],input[type="tel"]{
                @include input-padrao($light-gray, $black-80, true, 75px);
                margin-bottom: 10px;
                font-size:16px;
            }
            
            input.valid{
                @include input-padrao($light-gray, $black-80, true, 75px,true);
            }
            input.invalid{
                @include input-padrao($light-gray, $black-80, true, 75px,false);
            }

            label{
                @extend .f14;
                position: relative;
                top: -4px;
            }
            .form-box{
                @include form-box-padrao($white,20px,20px);
            }
            .fieldset:after{
                display:none;
            }
            .secondary{
                @extend .f14;
                margin-top: 12px;
            }

            .form-create-account .fieldset.create.account:after{
                display: none !important;
            }
            .fieldset.create.account{
                text-indent: -5000px!important;
                position: fixed !important;
                opacity: 0;
            }
    }



    @media only screen and (max-width: 600px) {
        .customer-account-login .block-customer-login  ,.customer-account-create .block-customer-register{
            padding: 0 ;
        }
    }
    @media only screen and (min-width: 600px) {
        .customer-account-login .block-customer-login  ,.customer-account-create .block-customer-register{
            width:450px !important;
        }
    }



    .block-authentication .block-customer-login,.block-authentication .block-new-customer{
        .block-title{
            border:none;
            text-align: center;
            @extend .f20;
            margin-bottom: 15px;
            margin-top: 35px;
            padding-bottom: 12px;
        }
    }

    .block-customer-login .fieldset.login{
        margin-bottom: 0px !important;
    }
}
