.minicart-wrapper .contador-de-quantidade {
    position: absolute;
    bottom: 0;
    right: 0;
}
.contador-de-quantidade {
  button {
    @include botao-padrao($black, $white, false);
    padding: 0;
    line-height: 26px;
    height: 25px;
    text-align: center;
    width: 25px;

    &:before {
      font-size: 9px;
      position: relative;
      top: -1px;
    }
  }

  .sub {
    position: relative;
    left: 4px;
    border-radius: 0;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    @include icon($position: before, $icon: minus);
  }

  .add {
    position: relative;
    right: 4px;
    border-radius: 0;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    @include icon($position: before, $icon: plus);
  }

  input {
    position: relative;
    top: 1px;
    @extend .f14;
    width: 50px !important;
    height: 24px;
    border-color: $black;
    //box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2);
  }
}

.custom-qty > a {
  display: inline-block;
  @include botao-padrao($black, $white, false);
  padding: 0;
  line-height: 26px;
  height: 25px;
  text-align: center;
  width: 25px;

  &:before {
    font-size: 9px;
    position: relative;
    top: -1px;
  }
}

.custom-qty input{
  position: relative;
  top: -1px;
  @extend .f14;
  width: 50px !important;
  height: 25px !important;
  border-color: $black;
  //box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2);
}

.ajax-cart-qty-minus {
  position: relative;
  left: 4px;
  border-radius: 0;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  @include icon($position: before, $icon: minus);
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.ajax-cart-qty-plus {
  position: relative;
  right: 5px;
  border-radius: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  @include icon($position: before, $icon: plus);
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.cart.table-wrapper .actions-toolbar > .action-edit:before, .cart.table-wrapper .actions-toolbar > .action-delete:before {
  color: #000;
}

