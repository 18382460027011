.customer-account-index {
    .page-title {
        @extend .f40;
        
        width: 100%;
        
        span {
            display: block;
            text-align: center;
        }
    }
}