#tuna_payment_method_content {
    #payment_tuna_payment {
        //display: block !important;
    }

    #lblHolderNameBoleto {
        display: none;
    }

    .lbl-billing-address {
        display: none;
    }

    #installmentsDiv {
        .label {
            span {
                font-size: 12px;
            }
        }
    }

    #newCardDiv {
        display: block;
        position: relative;
        .field {
            .label {
                span {
                    font-size: 12px;
                }
            }
        }
        
        #tuna_credit_card_code {
            max-width: 80px;
        }

        .jp-card-container {
            top: 0;
        }
    }

    
    #savedCardDiv {
        span {
            font-size: 14px;
        }

        input[type='radio'] {
            margin-top: 5px !important;
        }
    }
}

.select-year {
    margin: 0 5px;
}
.icon-payment {
    width:25px;
    opacity: 0.7;
    left: -2px;
    top: 7px;
    position: relative;
}


@media only screen and (min-width: 1023px) {
    #tuna_credit_card_holder, #tuna_credit_card_number {
        max-width: 270px;
    }
}