body {
    overflow-x: hidden !important;
}

.upsell, .related {

    .amlabel-position-wrapper {
        display: none !important;
    }

    clear: both;
    margin-top: -20px;
    .swatch-option.text {
        margin-right: 8px;
        padding: 4px 7px;
    }
    .block-title {
        margin: 0 0 10px;
        strong {
            @extend .f18;
            text-transform: uppercase;
            font-weight: normal;
            text-align: center;
            display: block;
            margin: 0 auto;
        }
    }

    .amrelated-name {
        text-transform: uppercase;
    }

    .product-items {
        align-items: start;
        justify-content: space-between;

        .product-item {
            position: relative;
            padding: 0;
            margin: 0 !important;
            width: 100% !important;
        }
    }

    .slick-item {
        position: relative;
    }

    .product-item:hover img.img-flipper{
        display: block;
    }

    .slick-dots {
        position: relative;
    }

    .products-grid .product-item-info:hover, .products-grid .product-item-info.active {
        padding: 0 !important;
        margin: 0 !important
    }

    .products-grid .product-item-info:hover, .products-grid .product-item-info.active {
        border: 1px solid transparent;
        box-shadow: none;
    }

    .actions-secondary {
        display: none !important;
    }

    .product-item-actions {
        text-align: center
    }

    .swatch-attribute-options{
        display: table;
        margin: 10px auto;
    }

    .actions-primary {
        margin-top: 6px;
    }

    .product-items.slick-slider .slick-slide .product-item {
        width: 100% !important;
        display: block !important;
        position: relative;
    }

    .slick-slide {
        vertical-align: top;
    }

    .product-items.slick-slider .slick-slide .product-item {
        max-width: 100% !important;
    }

    .product-item-name a {
        @extend .f12;
        text-transform: uppercase;
        text-align: center;
        display: block;
    }

    .price-container span {
        @extend .f12;
        text-align: center;
        display: block;
        margin: 0 auto;
    }

    .products-grid .product-item-info:hover {
        border: 0;
    }

    .slick-upsell > .product-item, .slick-related > .product-item {
        width: calc((100% - 4%) / 4) !important;
        position: relative;
    }


    .block.widget .products-grid .product-item .product-item-inner, .page-layout-1column .block.widget .products-grid .product-item .product-item-inner, .page-layout-3columns .block.widget .products-grid .product-item .product-item-inner {
        border: 0;
        box-shadow: none;
        margin: 0;
    }

    .products-grid .product-item-info:hover > .product-item-photo > .product-image-container{
        opacity: 0;
    }

    .products-grid .product-item-info:hover {
        background: transparent;
    }


    .product-item-photo {
        display: block;
        position: relative;
    }

    li.product-item .product-item {
        width: 100% !important;
    }

    .slick-prev {
        left: -15px !important;
    }

    .slick-upsell, .slick-related {
        padding: 0 20px;
    }

    img.img-flipper {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        width: 100%;
    }

    .price-box {
        text-align: center;
        .old-price {
            display: inline-block;
            position: relative;
            top: -2px;
            padding: 0;
            margin: 2px auto;
            margin-left: 5px;
        }
        .normal-price{
            display: block;
            padding: 0;
            margin: 0 auto;
        }
        .price {
            @extend .f14;
        }
    }
    .eth_installments {
        top: 0 !important;
        text-align: center;
        font-size: 14px;
    }

    .product-item .price-box {
        margin: 10px 0 10px;
    }

    /* the slides */
    .slick-slide {
        margin: 0 6px;
    }

    /* the parent */
    .slick-list {
        margin: 0 -6px;
    }

    .product-item-details {
        padding-bottom: 30px;
    }


    @media screen and (max-width:1023px){


        .slick-upsell .product-item, .slick-related .product-item {
            width: 100% !important;
            position: relative;
        }

        .slick-upsell > .product-item, .slick-related > .product-item {
            width: 100% !important;
            position: relative;
        }

        .slick-upsell, .slick-related {
            padding: 0;
        }

        .product-items.slick-slider .slick-slide .product-item {
            padding: 0;
        }

        .amrelated-pack-item .price-box {
            margin-bottom: 30px;
        }

        img.img-flipper {
            top: 0;
        }

        .block-title {
            text-align: center;
            strong {
                text-align: center;
            }
        }
    }
}
