.amrelated-pack-wrapper {
    li {
        list-style: none;
    }
    position: relative;

    .amrelated-title {
        strong {
            @extend .f18;
            text-transform: uppercase;
            text-align: center;
            font-weight: normal;
            display: block;
        }
    }

    .amrelated-background {
        box-shadow: none !important;
        background: transparent !important;
    }

    .amrelated-plus, .amrelated-equal {
        color: $black !important;
    }

    .amrelated-label {
        display: none;
    }

    a, a:hover, a:visited, .alink:visited {
        color: $black;
    }

    .action.primary {
        @include botao-padrao($black, $white, false);
    }

    .amrelated-discount {
        background: $black;
    }

    .amrelated-price-wrapper {
        border: 3px dashed $light-gray !important;
    }

    .amrelated-photo-container {
        border-color: transparent;
    }
}

.amrelated-pack-wrapper {
    display: flex;
    margin: -6px auto 0;
    width: max-content;
}

.amrelated-pack-wrapper .amrelated-title {
    padding: 10rem 4rem 0 0;
    margin-right: 3rem;
}

.amrelated-pack-wrapper .amrelated-content-wrapper {
    margin: 0;
    width: max-content;
}

.amrelated-pack-wrapper .amrelated-title strong {
    font-size: 1.8rem;
    font-weight: 600;
    position: relative;
    width: 20rem;
    text-align: right;
    font-style: italic;
}

.amrelated-pack-wrapper .amrelated-title strong:after {
    content: "";
    width: 31px;
    height: 19px;
    display: block;
    background-image: url(/media/icones/seta-black.png);
    background-size: 100% 100%;
    transform: rotate(180deg);
    position: absolute;
    top: 5rem;
    right: 0;
}

.amrelated-pack-wrapper .amrelated-discount.-product {
    display: none !important;
}

.amrelated-pack-item.-wide {
    padding: 0;
}

.amrelated-pack-item .amrelated-photo-container {
    margin: 0;
    width: max-content;
    height: auto;
    border: none;
}

.amrelated-photo-container .amrelated-photo {
    width: 100%;
    max-height: unset;
    border: 1px solid #000;
    max-width: 122px;
}

.amrelated-pack-wrapper .amrelated-name a,
.amrelated-pack-item .price-final_price [data-price-type=finalPrice] .price,
.amrelated-pack-item .price-final_price .old-price span.price {
    display: block;
    text-align: left;
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: 600;
}

.amrelated-pack-item .price-box {
    margin: 0;
    flex-direction: column;
}

.catalog-product-view .amrelated-pack-item .price-box .normal-price {
    margin: 0;
    order: 1;
    padding-left: 0;
}

.amrelated-pack-wrapper .amrelated-name a {
	margin-bottom: .3rem;
}

.amrelated-pack-item .amrelated-details {
    margin-bottom: 2rem;
}

.amrelated-pack-wrapper .amrelated-content-wrapper .amrelated-plus,
.amrelated-pack-wrapper .amrelated-content-wrapper .amrelated-equal {
    font-size: 3rem !important;
    padding: 0 ;
    width: 0;
    position: relative;
    z-index: 99;
    line-height: 21rem;
    display: block;
    height: 58%;
}

.amrelated-pack-wrapper .amrelated-content-wrapper .amrelated-plus
{
    left: -.95rem;
}

.amrelated-pack-wrapper .amrelated-content-wrapper .amrelated-equal {
    left: -.95rem;
}

.amrelated-content-wrapper .amrelated-pack-list .amrelated-pack-item {
    padding: 2rem 2rem 0 2rem;
    width: max-content;
}

.amrelated-pack-list.-wide .amrelated-pack-items {
    justify-content: left;
}

.amrelated-pack-item.-wide .amrelated-info {
    flex-direction: column;
}


.amrelated-pack-wrapper .amrelated-content-wrapper .amrelated-plus:after,
.amrelated-pack-wrapper .amrelated-content-wrapper .amrelated-equal:after {
    content: "";
    position: absolute;
    width: 25px;
    height: 40%;
    background-color: #fff;
    display: block;
    top: 19%;
    left: -3px;
    z-index: -1;
}

.amrelated-content-wrapper .amrelated-pack-list {
    max-width: 100%;
}

.amrelated-pack-item .amrelated-photo-container {
    height: auto;
}

.amrelated-content-wrapper .amrelated-summary-content {
    margin: 0;
}

.amrelated-content-wrapper .amrelated-summary-content .amrelated-price-wrapper {
    height: auto;
    padding: 0;
}

.amrelated-pack-wrapper .amrelated-price-wrapper {
    border: none !important;
}

.amrelated-discount.-summary {
    transform: unset;
    position: relative;
    background: no-repeat;
    color: #000;
    font-size: 0;
    display: block;
    width: 100%;
    padding: 0 !important;
}

.amrelated-discount.-summary .amount {
    display: none;
}

.amrelated-discount.-summary:after {
    content: "TOTAL:";
    font-size: 2rem;
    font-style: italic;
    font-weight: 700;
}

.amrelated-pack-wrapper .amrelated-summary-content .main.price,
.amrelated-pack-wrapper .amrelated-price-wrapper .main,
.amrelated-pack-wrapper .amrelated-price-wrapper .main.installmentPrice {
    display: block;
    width: 100%;
    text-align: left !important;
    font-size: 2rem;
    font-weight: 700;
}

.amrelated-content-wrapper .main.price + .main {
    font-size: 1rem;
    font-weight: 500;
    margin: 1rem 0;
}

.amrelated-content-wrapper .amrelated-summary-content {
    margin: 3rem 0 0 4rem;
}

.amrelated-pack-wrapper .amrelated-summary-content .main.price {
    margin-top: 4rem;
}

.amrelated-content-wrapper .amrelated-summary-content .amrelated-tocart-wrapper {
    margin-top: 4rem;
}

.amrelated-content-wrapper .amrelated-summary-content .amrelated-tocart-wrapper button.action.primary {
    border-radius: 0;
    background-color: #000;
}

.amrelated-content-wrapper .amrelated-summary-content .amrelated-tocart-wrapper button.action.primary span,
.amrelated-bundle-popup .amrelated-products-wrapper p.amrelated-title a {
    color: #fff;
    text-transform: uppercase;
    font-size: 1.5rem;
    font-style: italic;
    font-weight: 600;
}

.amrelated-content-wrapper .amrelated-summary-content .amrelated-tocart-wrapper button.action.primary span:after {
    content: " TUDO";
}

.amrelated-bundle-popup .amrelated-products-wrapper {
    padding: 4rem 7rem;
}

.amrelated-bundle-popup .amrelated-product-info .product-info-price .price-box span {
    display: block;
    text-align: left;
    width: 100%;
}

.amrelated-bundle-popup .amrelated-product-info .product-info-price .price-box .price-container .price {
    font-size: 2rem;
    color: #000;
}

.am-tabs-view .product-item {
    padding: 0;
    margin-top: 2rem;
}

.amrelated-bundle-popup div#product-options-wrapper {
    padding: 0;
}

.catalog-product-view .amrelated-bundle-popup .swatch-opt .swatch-attribute {
    flex-direction: column;
    align-items: baseline;
}

.catalog-product-view .amrelated-bundle-popup .swatch-opt .swatch-attribute span.swatch-attribute-label {
    margin-bottom: 2rem;
}

.amrelated-popup-container {
    z-index: 9999;
}

@media (max-width: 767px) {

    .amrelated-pack-wrapper .amrelated-title:after {
        display: none;
    }

    .amrelated-pack-wrapper .amrelated-title strong {
        width: 100%;
        text-align: center;
    }

    .amrelated-pack-wrapper {
        width: 100%;
        flex-direction: column;
        overflow: hidden;
    }

    .amrelated-pack-wrapper .amrelated-title {
        margin: 2rem 0;
        padding: 0 4rem;
    }

    .amrelated-pack-wrapper .amrelated-title strong:after {
        top: 1rem;
        right: -10rem;
        transform: rotate(270deg);
    }

    .amrelated-pack-item .amrelated-info {
        flex-direction: column;
        align-items: center;
    }

    .amrelated-content-wrapper .amrelated-pack-list .amrelated-pack-item {
        border: none;
        box-sizing: border-box;
        width: 100%;
    }

    .amrelated-content-wrapper .amrelated-summary-content {
        flex-direction: column;
        width: 100% !important;
        margin-left: 0;
        padding: 0 3rem 2rem;
    }

    .amrelated-pack-wrapper .amrelated-discount {
        left: 0;
    }

    .amrelated-content-wrapper .amrelated-summary-content .amrelated-price-wrapper {
        margin: 0;
        height: auto;
    }

    .amrelated-pack-wrapper .amrelated-summary-content .main.price {
        margin-top: 1rem;
    }

    .amrelated-content-wrapper .main.price + .main {
        margin: 0.8rem 0 0;
    }

    .amrelated-content-wrapper .amrelated-summary-content .amrelated-tocart-wrapper {
        margin-top: 2rem;
        margin-right: 0;
        text-align: center;
    }

    .amrelated-pack-wrapper .amrelated-content-wrapper {
        width: 100%;
    }

    .amrelated-pack-item .amrelated-details {
        margin: 0;
        width: 100%;
        justify-content: center;
    }

    .amrelated-pack-item .amrelated-name a.amrelated-link {
        text-align: center;
    }

    .amrelated-photo-container .amrelated-photo {
        max-width: 120px;
    }

    .amrelated-pack-wrapper .amrelated-name a,
    .amrelated-pack-item .price-final_price [data-price-type=finalPrice] .price {
        font-size: 1.5rem;
    }

    .amrelated-grid-wrapper {
        margin-top: 4rem;
    }

    .amrelated-bundle-popup .amrelated-products-wrapper {
        padding: 1rem 0;
    }

    .amrelated-bundle-popup p.amrelated-title {
        margin-top: 2rem;
    }

    .amrelated-bundle-popup .amrelated-image-container {
        border: none;
    }

    .amrelated-pack-wrapper .amrelated-title:after {
        display: none;
    }

    .amrelated-pack-wrapper .amrelated-content-wrapper .amrelated-plus,
    .amrelated-pack-wrapper .amrelated-content-wrapper .amrelated-equal {
        line-height: 2rem;
        width: 100%;
        text-align: center;
        left: 0;
    }

    .amrelated-pack-wrapper .amrelated-content-wrapper .amrelated-plus:after,
    .amrelated-pack-wrapper .amrelated-content-wrapper .amrelated-equal:after {
        display: none;
    }

    .amrelated-pack-wrapper .dizy-installment {
        width: 100%;
    }

    .amrelated-pack-wrapper .dizy-installment span.price {
        text-align: center;
    }

    .amrelated-pack-wrapper .amrelated-summary-content .dizy-installment .best-installment {
        height: 20px;
    }

    .amrelated-pack-wrapper .dizy-installment .price {
        text-align: center !important;
    }

    .amrelated-pack-wrapper .amrelated-content-wrapper .amrelated-plus,
    .amrelated-pack-wrapper .amrelated-content-wrapper .amrelated-equal {
        height: auto;
    }
    
}