@media screen and (max-width:1023px){
    .page-layout-3columns {
        .amrelated-pack-wrapper {
            .amrelated-summary-content  {
                padding: 0;
            }
            .amrelated-pack-item.-main {
                background: none;
            }
            .amrelated-plus, .amrelated-equal {
                display: none;
            }
            .amrelated-price-wrapper {
                display: none;
            }
            button.action.primary {
                max-width: 250px;
                margin: 0 auto;
                span{
                    font-style: normal !important
                }
            }
        }
        .product-info-main {
            padding: 8px;
            box-sizing: border-box;
            width: 100% !important;
            position: sticky;
            bottom: 1px;
            float: none;
            max-width: 100%;
            background: #f7f7f7;
            z-index: 2;

            .product.attribute.sku {
                display: none;
            }
            
            .product-info-price .price-box, .dizy-installment .best-installment, .product-info-price {
                margin: 0;
                padding: 0;

                .installment {
                    font-size: 14px;
                }

                .price {
                    font-size: 16px;
                    top: 0 !important;
                }
            }

            .dizy-installment {
                padding: 0;
                margin: 0;
            }
            .page-title-wrapper, .product-info-price {
                display: inline-block;
                width: 50%;
                .page-title {
                    margin: 0;
                }
            }
            .product-add-form {
                padding: 0;
                
                #product_addtocart_form {
                    display: block;
                }
            }

            .fieldset:last-child {
                margin-bottom: 0;
            }

            .product-info-price .widget {
                display: none;
            }

            .product-social-links {
                float: right;
                display: none;
                a {
                    padding: 0 14px;
                    box-sizing: border-box;
                }
            }
        }
    }
}

@media screen and (min-width:1023px){
    .page-layout-3columns {
        .column.main {
            width: 100% !important;
        }

        .swatch-opt .swatch-option {
            height: 40px;
            line-height: 32px;
        }

        .box-tocart .action.tocart {
            margin-top: 7px;
        }

        .breadcrumbs {
            display: none;
        }

        .amrelated-pack-wrapper {

            .amrelated-plus, .amrelated-equal {
                display: none !important;
            }

            flex-direction: column;
            align-items: center;
            .amrelated-title, .amrelated-title strong {
                width: 100%;
                padding: 0;
                margin: 0;
                text-align: center;
                font-style: normal;
            }
            .amrelated-photo {
                border: 0;
            }
        }

        .amrelated-pack-wrapper, .amrelated-grid-wrapper, .upsell, .hfb-footer{
            z-index: 3;
            width: 100%;
            background: #fff;
            position: relative;
        }

        .amrelated-pack-wrapper {
            padding-bottom: 24px;
            margin-bottom: 24px;
            .best-installment {
                text-align: left;
            }
            .amrelated-content-wrapper {
                flex-direction: column;
                align-items: center;
                width: 100%;
                button.action.primary {
                    max-width: 250px;
                    margin: 0 auto;
                    span{
                        font-style: normal
                    }
                }
                .amrelated-price-wrapper {
                    display: none;
                }
                .amrelated-summary-content  {
                    width: 100%;
                }
                .amrelated-pack-items, .amrelated-pack-item {
                    margin: 0;
                    padding: 0;
                    
                    .amrelated-info {
                        background: transparent;
                        margin: 0;
                        padding: 0;
                        box-sizing: border-box;
                        .amrelated-details {
                            margin: 0;
                        }
                        .amrelated-photo-container {
                            width: 100%;
                            background: transparent;
                        }
                    }
                }
                background: transparent;
            }
            background: #F9F9F9;
        }


        .hfb-footer {
            margin-top: -50px;
        }
        #maincontent{
            max-width: 100% !important;
        }

        .product.media {
            width: 100% !important;
            float: none;
        }

        .amasty-gallery-image {
            width: auto !important;
        }
        
        #real-image {
            margin: 0 auto;
            width: auto !important;
            //display: none !important;
        }

        #amasty-gallery-container {
            width: 100% !important;
            position: relative !important;
        }

        #amasty-main-container {
            margin-left: 0;
        }

        .product-info-main {
            padding: 14px 10vw 10px;
            box-sizing: border-box;
            width: 100% !important;
            position: sticky;
            bottom: 0;
            float: none;
            max-width: 100%;
            background: #f7f7f7;
            z-index: 2;

            .product.attribute.sku {
                display: none;
            }
            
            .product-info-price .price-box, .dizy-installment .best-installment, .product-info-price {
                margin: 0;
                padding: 0;

                .installment {
                    font-size: 14px;
                }

                .price {
                    font-size: 16px;
                }
            }
            .page-title-wrapper, .product-info-price {
                display: inline-block;
                width: 50%;
                .page-title {
                    margin: 0;
                }
            }
            .product-add-form {
                float: right;
                padding: 0;
                margin-top: -20px;
                
                #product_addtocart_form {
                    display: flex;
                }
            }

            .product-info-price .widget {
                display: none;
            }

            .product-social-links {
                float: right;
                display: none;
                a {
                    padding: 0 14px;
                    box-sizing: border-box;
                }
            }
        }

    }
}