.codeseller {
  padding-bottom: 1px;
  margin-bottom: 10px;
}

.codeseller-box , .cart-summary #block-discount {
  @media only screen and (max-width: 1023px) {
    padding: 0 15px;
  }
  p {
    margin-bottom: 2px;
    font-size: 10px !important;
  }

  input[type="text"]{
    @include input-padrao($white, $black-80, false, 75px);
    border: 1px solid $black-777;
    border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    float: left;
  }

  #success-codeseller{
    color: $green;
    display: none;
    text-align: left;
 }

 #error-codeseller {
   display: none;

 }
 
 #error-remove-codeseller{
    color: $red;
    display: none;
 } 

  span {
    @extend .f12;
  }

  button {
    @extend .f14;
    width: 75px;
    text-align: center;
    font-weight: normal;
    @include botao-padrao($black, $white, false, 45px);
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    line-height: 0;
  }
}