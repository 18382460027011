@media screen and (min-width:1023px){
    .page-layout-modelo-full {
        .product-info-main {
            width: 40%;
            position: sticky;
            top: 12vh;
        }

        .product.media {
            width: 57%;
        }

        .amasty-gallery-image {
            width: auto !important;
        }
        
        #real-image {
            margin: 0 auto;
            width: auto !important;
            //display: none !important;
        }

        #amasty-gallery-container {
            width: 100% !important;
            position: relative !important;
        }

        #amasty-main-container {
            margin-left: 0;
        }
    }
}