@mixin input-padrao($background, $textcolor, $fullWidth, $button-width:null ,$isValid:null) {
  border-radius: 3px;
  color: $textcolor;
  height: 45px;
  padding-bottom: 2px;
  background: $background;
  border: 0;
  @extend .f16;
  &::placeholder {
    @extend .f10;
    color: $black-60;
  }

  &:focus {
    cursor: pointer;
  }

  &.valid {

    @if $button-width != null {
      border-top: 1px solid $green;
      border-left: 1px solid $green;
      border-bottom: 1px solid $green;
      box-sizing: border-box;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }

  &.invalid {
    box-shadow: inset 0px 0px 0px 1px $red;
    box-sizing: border-box;
  }

  @if $isValid == true {
     border: 1px solid $green;
  }

  @if $isValid == false {
      border: 1px solid $red;
  }


  @if $fullWidth == true {
    display: block;
    width: 100%;
  }

  @if $fullWidth == false {
    display: inline-block;
    width: calc(100% - #{$button-width});
    float: left;
  }
}

input[type="text"] {
  font-size: inherit !important;
}

@media (max-width: 768px) {
    input[type="text"], input[type="number"], input[type="tel"] {
        font-size: 16px !important;
    }
}
