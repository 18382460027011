.carregando {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $white;
  z-index: 9999;
  text-align: center;

  img {
    position: relative;
    top: 30%;
    width: 50px;
    @include center('h');
  }
}
