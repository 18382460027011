@function rem($values...) {
  @if $rem-px-only {
    @return rem-convert(px, $values...);
  }
  @else {
    @return rem-convert(rem, $values...);
  }
}

@function rem-convert($to, $values...) {
  $result: ();
  $separator: rem-separator($values);

  @each $value in $values {
    @if type-of($value) == 'number' and unit($value) == 'rem' and $to == 'px' {
      $result: append($result, $value / 1rem * $rem-baseline, $separator);
    }
    @else if type-of($value) == 'number' and unit($value) == 'px' and $to == 'rem' {
      $result: append($result, $value / $rem-baseline * 1rem, $separator);
    }
    @else if type-of($value) == 'list' {
      $value-separator: rem-separator($value);
      $value: rem-convert($to, $value...);
      $value: rem-separator($value, $value-separator);
      $result: append($result, $value, $separator);
    }
    @else {
      $result: append($result, $value, $separator);
    }
  }

  @return if(length($result) == 1, nth($result, 1), $result);
}

@function rem-separator($list, $separator: false) {
  @if $separator == 'comma' or $separator == 'space' {
    @return append($list, null, $separator);
  }

  @if function-exists('list-separator') == true {
    @return list-separator($list);
  }

  $test-list: ();
  @each $item in $list {
    $test-list: append($test-list, $item, space);
  }

  @return if($test-list == $list, space, comma);
}
