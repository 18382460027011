
html.nav-open body {
    overflow: hidden;
}

@media only screen and (min-width: 1023px) {
    .navigation .ui-menu-icon {
        position: relative !important;
        left: 10px;
        float: right !important;
    }
}

@media only screen and (max-width: 1023px) {
    header {
        .col-md-10{
            width:70%;
        }
        
        .col-md-2{
         width:30%;
        }
        
        .page-layout-1column, .page-layout-2columns-left, .page-layout-2columns-right, .page-layout-1column-fullwidth{
            overflow-x: initial;
        }

        .nav-sections-item-title {
            display: none;
        }

        header input {
            font-size: 16px !important;
        }

        .nav-open .nav-sections {
            width: 100vw;
            z-index: 999;
            top: 0;
        }
    
        header .nav-sections-item-title{
            display: none;
        }
        
        .nav-toggle {
            z-index: 99;
        }

        .nav-toggle:after {
            content: none !important;
        }
    
        .i-up:after {
            content: '\e621' !important;
        }
    
        .i-close:before {
            content: '\e616' !important;
            font-size: 20px;
            top: 4px;
            left: 4px;
            position: relative; 
        }
    
        #megamenu {
   
            .nav-toggle:before {
                content: '\e616' !important;
                font-size: 20px;
                top: 4px;
                left: 4px;
                position: relative;        
            }
    
            .i-up:after {
                content: '\e621' !important;
            }
        
            .i-close:before {
                content: '\e616' !important;
                font-size: 20px;
                top: 4px;
                left: 4px;
                position: relative; 
            }
    
            .topmenu {
                .category-item .ui-menu-icon {
                    width: 55px;
                    height: 35px;
                    display: block;
                    position: absolute;
                    top: 2px;
                    right: -47px;
                    z-index: 999;
                }
    
                .nav-open .nav-toggle:after {
                    content: none !important;
                }
    
                .nav-toggle {
                    z-index: 999999;
                }
    
                .parent .level-top:after {
                    right: -30px !important;
                }
    
                .category-item a {
                    padding: 8px 0px 8px 15px !important;
                    margin-right: 50px !important;
                }
            }
        
            .nav-sections {
                width: calc(30% - 54px);
            }
        }
    
        #megamenu {
            .topmenu .parent .level-top:after {
                right: -30px !important;
            }
        }
    
        .hfb-logo-hamburger .logo {
            margin-left: 0 !important;
            position: relative;
            left: 50px;
            max-height: 0px;
        }

        header .mgz-element-search_form-wrapper {
            position: relative;
        }
    
        header .nav-sections-item-content {
            margin-top: 64px !important;
        }
    
        .navigation .parent .level-top:after {
            font-size: 1.6rem;
            right: 10px;
            top: 7px;
        }
    
        .logo-header, .megamenu > .topmenu {
            display: block;
        }
    }
}

header #megamenu .topmenu .category-item a {
    position: relative;
}

header .mgz-carousel-nav-size-small {
    display: none !important;
}

    
@media only screen and (max-width: 1023px){
    header {

        .navigation .icon-more:after{
            content: attr(data-after);
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            font-size: 20px;
            line-height: inherit;
            color: inherit;
            content: '\e622';
            font-family: 'luma-icons';
            vertical-align: middle;
            display: inline-block;
            font-weight: normal;
            overflow: hidden;
            speak: none;
            text-align: center;
            width: 55px;
            height: 35px;
            display: block;
            position: absolute;
            top: 2px;
            right: -47px;
            z-index: 999;
        }
        
        .clicked .icon-more:after{
            content: '\e621';
        }
        
        #megamenu .topmenu .parent .level-top:after {
            content: '';
        }
        
        .navigation .submenu {
            display: none;
        }

        .block-search input {
            margin: 12px 0;
        }
    
        .amsearch-wrapper-input {
            width: 95%;
        }
    }
}

@media (min-width: 767px) and (max-width: 1023px) {
    header {
        .mgz-element-search_form-wrapper {
          position: static;
        }
        .mgz-element-search_form-wrapper > .mgz-element-inner {
          position: static;
        }
        .mgz-element-search_form {
          position: static;
        }
        .mgz-element-search_form > .mgz-element-inner {
          position: static;
        }
        .mgz-element-search_form .minisearch.active .control {
          display: block;
        }
        .mgz-element-search_form .block-search .control {
          border: 0;
          width: 100%;
          left: 0;
          right: 0;
          margin: 0;
          bottom: 0;
          position: absolute;
          background: #FFF;
        }
        .mgz-element-search_form .block-search .form.active .control input {
          position: static;
        }
    }
}


