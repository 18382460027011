input[type='radio'], input[type='checkbox'] {
    -webkit-appearance:none;
    width:20px;
    height:20px;
    border:1px solid darkgray;
    border-radius:50%;
    outline:none;
    box-shadow:0 0 1px 0px gray inset;
}
input[type='radio']:before, input[type='checkbox']:before {
    content: '';
    display: block;
    width: 14px;
    height: 14px;
    margin: 10%;
    border-radius: 50%;
}

input[type='radio']:checked:before, input[type='checkbox']:checked:before {
    background: $green;
}