  #block-discount{

    @media only screen and (max-width: 1023px) {
      padding: 0 15px;
    }

    .lista-de-cupom {
      position: absolute;
      top: -16px;
      z-index: 99;
      .cupom-item{
        padding: 1px 5px;
      }
    }
    position: relative;
    .content{
       display:block;
    }
    .label {
      display: none;
    }
    .fieldset{
      margin: 0;
    }

    button{
      @include botao-padrao($black, $white, true, 45px);
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      line-height: 0;
      @extend .f14;
      width: 75px;
      font-weight: normal;
    }

    input[type="text"]{
      @include input-padrao($white, $black-80, false, 75px);
      border: 1px solid $black-777;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      float: left;
    }

    button{
      @include botao-padrao($black, $white, true, 45px);
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      line-height: 0;
      @extend .f14;
      width: 75px;
      font-weight: normal;
    }

    .content{
      padding-bottom: 1px !important;
    }
  }

  .coupon_error{
    color: $red;
    @extend .f10;
    position: relative;
    top: -4px;
  }
