#box-mercadopago {
    max-width: 690px;
    margin: 0 auto;
    text-align: center;
}

.mercadopago-title, #box-mercadopago > p:nth-child(2) {
    display: none;
}

.mp-steps-congrats, .mp-details-pix-title{
    display: none;
}

.mp-details-title, .mp-details-pix-img{
    display: none;
}


.mp-details-pix, #box-mercadopago .main .content-box{
    background-color: transparent;
}

#logo-mercadopago,.page-layout-1column .column.main > .primary.button-success {
    display: none;
}

.mp-details-pix-button, .mp-details-pix-button:hover, #btn-boleto-mercadopago, #btn-boleto-mercadopago:hover{
    background: forestgreen;
    color: #fff;
    margin: 10px auto;
    border: 0 !important;
}
.mp-qr-input {
    margin: 0 auto;
    display: block;
}

.mp-flex.mp-mb-16{
    display: none;
}

.text-container {
    text-align: center;
}

.checkout-onepage-success .page-title-wrapper{
    display: none;
}