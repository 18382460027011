.product-slider{
    .product-item-info {
        position: relative;
    }

    .product-item-info:hover > .product-item-photo > .product-image-container > .product-image-wrapper > .product-image-photo {
        opacity: 0!important;
    }

    .product-item-info:hover > .product-item-photo > .product-image-container > .product-image-wrapper > .product-image-photo-no-flipper {
        opacity: 0!important;
    }

    .ondemand-button-wrapper {
        margin-top: 30px;
    }

    .product-item-info:hover > .product-item-photo > .img-flipper {
        display: block;
    }

    img.img-flipper {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
    }

    .product-item-actions {
        display: flex;
        justify-content: center;
    }
}

