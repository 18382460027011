.swatch-opt {
    margin: 0;

    .swatch-attribute {
        margin-bottom: 30px;
    }

    .swatch-attribute-label {
        @extend .f10;
        font-weight: normal;
        text-transform: uppercase;
    }

    .swatch-option {
        border: 1px solid $black-70;
        background: transparent;
        padding: 4px;
        margin-right: 26px;
        box-sizing: border-box;
        height: 30px;   
        width: 40px;
    }

    .swatch-attribute-options {
        margin-top: 8px;
    }

    .swatch-attribute-selected-option {
        display: none;
    }

    .swatch-option.image:not(.disabled):hover, .swatch-option.color:not(.disabled):hover {
        outline: 0;
        box-shadow: 0 0 0 2px $light-gray;
        border: 1px solid #000;
    }


    .swatch-option.selected {
        outline: 0;
        box-shadow: 0 0 0 2px $light-gray;
        border: 2px solid #000;
    }
}

@media screen and (max-width:1023px){
    .catalog-product-view .swatch-opt .swatch-option {
        margin-right: 16px;
    }
}