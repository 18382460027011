.mgk-adcpopup-modal {
    left: 0 !important;

    .modal-header {
        text-align: center;
        h1 {
            border: 0;
        }
    }

    .product-item-details {
        text-align: center;

        .product-item-actions {
            display: none;
        }
    }

    .product-item-sku, .details-qty{
        display: none;
    }

    .content-cart > .product-items{
        list-style: none;
        padding: 0;
    }

    .product-item-photo {
        margin: 0 auto;
        display: table;
    }

    .esummary-actions {
        display: table;
        margin: 0 auto;
        a {
            background-color: #000;
            color: #fff;
            padding: 6px 18px;
            margin:  0 6px;
            text-decoration: none;
            top: 16px;
            position: relative;
        }
    }
}

@media only screen and (min-width : 1025px) {
    .mgk-adcpopup-modal {
        .modal-inner-wrap {
            max-width: 520px;
            padding-bottom: 60px;
        }
    }
}

@media only screen and (max-width : 1025px) {
    .esummary-actions {
        a {
            width: 100%;
            display: block;
            margin: 10px 0 !important;
            text-align: center;
            padding: 6px !important;
        }
    }
}