.checkout-cart-index {
    .cart-container {
        .form-cart {
            float: none;
            width: 100%;
        }
    }

    .cart-summary #block-shipping #shipping-zip-form button {
        color: #000;
        background: #fff;
        border: 1px solid #000;
        padding: 10px 14px;
    }

    .cart.table-wrapper .item-options dd,
    .minicart-items .product-item-details .product .options.list dd,
    .opc-block-summary .items-in-cart .product .item-options dd,
    .block-giftregistry-shared-items .product .item-options dd,
    .multicheckout .item-options dd {
        font-size: 12px;
    }

    .cart.table-wrapper .cart > .item .item-actions .actions-toolbar a.action-towishlist {
        display: none;
    }

    .cart.table-wrapper .cart > .item {
        border-bottom: 3px solid #FFF;
        background: #eee;
        position: relative;
        padding: 7px 7px;
        margin-top: 0;
        margin-bottom: 7px;
    }

    .page-main {
        max-width: 450px;
        padding: 0 15px;
    }
    .page-title {
        font-size: 18px;
        font-weight: bold;
        position: relative;
        margin-top:30px;
    }

    .cart-totals .mark {
        text-align: left;
    }

    .cart-totals .grand .mark, .cart-totals .grand .amount {
        border-top: 0;
    }
    
    .cart-totals .mark {
        max-width: none;
    }

    #shopping-cart-table {

        position: relative;

        .product-item-photo {
            max-width: 50px;
        }

        .cart.table-wrapper .cart > .item .item-actions {
            position: relative;
            
        }
    }

    .cart.table-wrapper .col.price {
        text-align: center;
        font-weight: bold;

        .price-excluding-tax {
            .price {
                font-size: 12px;
            }
        }
    }

    .cart.table-wrapper .col.subtotal {
        text-align: right;
        font-weight: bold;
    }

    .cart.table-wrapper .item-options dt {
        font-weight: normal;
        font-size: 14px;
    }

    .cart.table-wrapper .product-item-name + .item-options {
        margin-top: 5px;
    }

    .cart.table-wrapper .product-item-name{
        font-size: 14px;
    }

    .cart.table-wrapper span.price {
        font-size: 14px;
        font-weight: normal !important;
    }


    #block-discount {
        padding: 0;
    }

    #block-discount > .content{
        display: block !important;
    }

    #block-discount > .title {
        display: none;
    }

    #shopping-cart-table {
        width: 420px;
        display: block;

        thead {
            display: block;
        }

        thead tr {
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid #cccccc;
            height: 50px;
            padding: 0 8px;
        }
    }

    .cart.table-wrapper {

        .col.subtotal {
            display: none;
        }

        .col.price {
            float: right;
            height: 100px;
            padding-right: 2px;
        }

        .cart>.item tr.item-info {
            display: block;
        }

        .item td.col.qty a,
        .item td.col.qty input {
            transform: translate(70px, 70px);
        }

        .item .col.item .cart.item.message.error div:before {
            font-size: 12px;top: 8px;left: -13px;
        }

        .product-item-details {
            padding-bottom: 10px;
        }

        .product-item-name+.item-options dt,
        .product-item-name+.item-options dd {
            margin-bottom: 0;
        }

        .product-item-name {
            font-size: 12px;
            font-weight: 900;
            margin-top: 10px;
        }

        .item-options dt {
            font-size: 12px;
        }

        .cart>.item tr.item-actions {
            width: 0;
            display: block;
            transform: translate(0, -25px);
            position: absolute;
            right: 8px;
            bottom: -27px;
        }

        .cart thead tr th.col.qty {
            display: none;
        }

        .item .col.item {
            display: block !important;
            width: 350px;

            .cart.item.message.error {
                margin: 0;
                padding: 1px 5px 1px 20px;
                width: max-content;
                font-size: 10px;
            }
        }

        .item td.col.qty {
            padding: 0;
            height: 100px;
        }

        .actions-toolbar {
            margin-right: 15px;
        }

        .cart thead tr th.col {
            border: none;
        }

        .cart>.item {
            display: block;
        }
    }

    main .cart-summary {

        padding: 15px;
        float: none;
        width: 100%;

        #block-discount .lista-de-cupom {
            z-index: 1 !important;
        }
    }

    .cart-summary .checkout-methods-items {
        position: relative !important;
        z-index: 0 !important;
        margin: 0 !important;
        padding: 0 !important;
    }

    .cart-summary > .title {
        display: none;
    }

    .cart-summary .codeseller-box, .cart-summary .cart-summary #block-discount {
        padding: 0 !important;
    }

    .cart.table-wrapper .product-item-photo {
        padding-top: 5px;
    }

    #discount-coupon-form {
        .title {
            display: none;
        }
    }

    .cart.main.actions .update {
        @extend .f14;
        text-align: center;
        font-weight: normal;
        @include botao-padrao($black, $white, false, 45px);
        line-height: 0;
    }

    #co-shipping-method-form label,
    #co-shipping-method-form span,
    main .cart-summary #block-shipping .label{
        font-size: 12px;
    }

    main .cart-summary .checkout-methods-items{
        width: 100%;
    }

    main .cart-summary .discount-box {
        margin-top: 8px;
    }

    main .cart-summary .cart-totals .price {
        margin-left: 12px;
    }

    @media only screen and (min-width: 1023px) { 
        .cart.table-wrapper .product-item-details{
            padding-top: 5px;
        }
        .cart.table-wrapper .item .col.item {
            padding: 5px;
            min-height: 0;
            height: 0;
            display: inline-table;
        }

    }

    div[data-bind="scope: 'amgiftwrap'"] {
        display: none;
    }

    .cart-summary .checkout-methods-items .action.primary.checkout {
        background-color: forestgreen;
    }

    .cart-summary .cart.table-wrapper .actions-toolbar .action-towishlist {
        display: none;
    }

    @media only screen and (max-width: 1023px) {
        .cart.table-wrapper .col.price {
            display: none;
        }
        .cart.table-wrapper .col.subtotal {
            margin-left: 40px;
        }
        .cart-summary .block .content {
            padding: 0;
        } 

        .cart-container #form-validate {
            margin-top: 50px;
        }

        .cart.table-wrapper .item .col.item {
            display: table-cell;
        }
        main .cart-summary {
            position: relative;
            width: 100%;
            margin: 20px auto;
            padding: 0;

            .checkout-methods-items {
                position: fixed !important;
                bottom: 0;
                z-index: 4 !important;
                padding: 5px 5px 23px !important;
                width: 90vw;
            }
        }

        .cart.table-wrapper {
            
            .product-item-details {
                padding-top: 5px;
                max-width: 110px;
                float: left;
            }

            .item .col.item {
                padding: 0 15px 10px 90px !important;
                min-height: 0;
                height: 0;
            }

            .col.qty {
                padding: 0 11px 10px;
                float: right;
            }

            .col.qty[data-th]:before {
                display: none;
            }

        }
    }

    @media (max-width: 767px) {
        .cart.table-wrapper {
            
            .cart>.item tr.item-info {
                height: 110px;
            }

            .item td.col.qty {
                height: 20px;
                float: left;
            }

            .item td.col.qty a,
            .item td.col.qty input {
                transform: translate(87px, 0);
                z-index: 2;
            }

            .actions-toolbar > .action-delete {
                right: 20px;
            }
        
            .actions-toolbar > .action-edit {
                margin-right: 35px;
            }

            .col.price {
                display: block;
            }

            .cart>.item td.col.price:before {
                display: none;
            }

            .cart thead tr th.col.item {
                display: block;
            }

        }

        #shopping-cart-table {
            overflow: hidden;

            thead th.col.price {
                text-align: right;
            }

        }
    }
    
}