/*Popup cookies*/
button.amgdprcookie-done {
    background-color: #000 !important;
}

form#amgdprcookie-form a.amgdprcookie-options {
    color: #333;
    width: 200px;
}

@media (min-width: 768px) {
    p.amgdprcookie-description {
        display: none;
    }

    .amgdprcookie-bar-container {
        padding: 20px 10%;
    }

    p.amgdprcookie-policy {
        max-width: 65% !important;
        float: left;
        font-size: 16px;
        margin: 10px 0 !important;
    }

    .amgdprcookie-buttons-block {
        justify-content: end;
        align-items: center;
    }


    button.amgdprcookie-button.-allow.-save {
        margin-top: 0;
    }

    button.amgdprcookie-button.-settings {
        margin-top: 0;
    }

    .amgdprcookie-bar-container .amgdprcookie-buttons-block .amgdprcookie-button {
        /* width: 180px !important; */
    }
}