.amxnotif-block {
    .subtitle {
        color: $black-80;
        @extend .f12;
        font-family: 'roboto-regular', sans-serif;
    }
    label {
        @extend .f18;
        font-family: 'gotham-light', sans-serif;
        margin-bottom: 10px;
    }
    input {
        @include input-padrao($light-gray, $black-80, true);
    }
    button {
        @extend .f14;
        width: 100%;
        position: relative;
        left: -4px;
        text-align: center;
        font-weight: normal;
        @include botao-padrao($black, $white, false, 45px);
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        line-height: 0;
    }
}

.page-products {
    .amxnotif-block { 
        label {
            @extend .f12;
        }
        button {
            @extend .f10;
            margin: 0 !important;
            padding: 10px !important;
            position: relative;
            left: -4px;
            width: 100%;
            text-align: center;
            font-weight: normal;
            @include botao-padrao($black, $white, false, 45px);
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            line-height: 0;
        }
    }
}

.amstockstatus-stockalert{
    background: #fafafa;
    padding: 10px;
    margin-bottom: 40px;
}