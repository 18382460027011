.e-accordion {
    .ui-accordion-header {
        background-color: $white;
        -webkit-box-shadow: 1px 0px 5px 0px rgba(176, 176, 176, 0.6);
        -moz-box-shadow: 1px 0px 5px 0px rgba(176, 176, 176, 0.6);
        box-shadow: 1px 0px 5px 0px rgba(176, 176, 176, 0.6);
        padding: 14px 20px;
        border-radius: 8px;
        margin-bottom: 24px !important;
    }
    .ui-accordion-content {
        background-color: $white;
        position: relative;
        margin-top: -38px;
        margin-bottom: 24px;
        border-bottom-left-radius: 8px;
        border-top-left-radius: 8px;
        -webkit-box-shadow: 1px 5px 5px 0px rgba(176, 176, 176, 0.6);
        -moz-box-shadow: 1px 5px 5px 0px rgba(176, 176, 176, 0.6);
        box-shadow: 1px 5px 5px 0px rgba(176, 176, 176, 0.6);
        height: auto !important;
    }
}