// ------------------------------------------------------------------------------
// MIXINS TOOLS
// ------------------------------------------------------------------------------

@mixin font-face($name, $path, $weight: null, $style: null, $exts: eot woff2 woff ttf svg) {
  $src: null;

  $extmods: (
    eot: '?',
    svg: '#' + str-replace($name, ' ', '_')
  );

  $formats: (
    otf: 'opentype',
    ttf: 'truetype'
  );

  @each $ext in $exts {
    $extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
    $format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
    $src: append($src, url(quote($path + '.' + $extmod)) format(quote($format)), comma); /* stylelint-disable-line function-url-quotes */
  }

  @font-face {
    font-display: swap;
    font-family: quote($name);
    font-style: $style;
    font-weight: $weight; 
    src: $src;
  }
}

@mixin center($type) {
  position: absolute;
  @if $type == 'v' {
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  @if $type == 'h' {
    left: 50%;
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
  }
}

@mixin rem-baseline($zoom: 100%) {
  font-size: $zoom / 16px * $rem-baseline;
}

@mixin rem($properties, $values...) {
  @if type-of($properties) == 'map' {
    @each $property in map-keys($properties) {
      @include rem($property, map-get($properties, $property));
    }
  }
  @else {
    @each $property in $properties {
      @if $rem-fallback or $rem-px-only {
        #{$property}: rem-convert(px, $values...);
      }
      @if not $rem-px-only {
        #{$property}: rem-convert(rem, $values...);
      }
    }
  }
}

@mixin icon($position: before, $icon: false, $size: false, $spacing: false, $lado: false, $top: false ,$styles: true) {
  @if $position == both {
    $position: 'before, &:after';
  }

  &:#{$position} {

    @if $size {
      font-size: $size;
    }

    @if $top {
      position: relative;
      top: $top;
    }

    @if $spacing {
      @if $lado {
        padding-#{$lado}: $spacing;
      }
      @else {
        padding: 0 $spacing;
      }
    }

    @if $icon {
      content: '#{map-get($icons, $icon)}';
    }

    @if $styles {
      font-family: 'Font Awesome 5 Free'; /* stylelint-disable-line font-family-no-missing-generic-family-keyword */
      font-style: normal;
      font-variant: normal;
      font-weight: 600;
      speak: none;
      text-transform: none;
    }

    @content;
  }
}

@mixin clearfix() {
  & {
    *zoom: 1;
  }
  &:before,
  &:after {
    content: "";
    display: table;
  }
  &:after {
    clear: both;
  }
}
