@media screen and (min-width:1023px){
    .page-layout-modelo-mosaico .product.media {
        .product-info-main {
            width: 40%;
            position: sticky;
            top: 12vh;
        }

        width: 57%;

        .amasty-gallery-image {
            width: auto !important;
        }

        #amasty-main-container {
            margin: 0 auto;
            //display: none;
        }

        #amasty-gallery-container {
            width: 100% !important;
            position: relative !important;
        }

        #amasty-gallery-images span:nth-child(-n+2), #real-image span:nth-child(-n+2){
            width:  49%;
            float:left;
            margin-left: 1%;
        }

        #amasty-gallery-images span:nth-child(n+3), #real-image span:nth-child(n+3){
            width:  32%;
            float:left;
            margin: 1% 0 0 1.3%;
        }

        .slick-slide {
            max-width: 48.5%;
            float: left !important;
            margin: 0 !important;
        }
    }
}
