#spinpay-container {
    text-align: center;
    
    font-size: 14px;
    
    .form__digital_wallet--qrcode_content {
        padding: 16px;
        box-shadow: 0 8px 16px #00000a14;
        -webkit-box-shadow: 0 8px 16px #00000a14;
        border-radius: 4px;
    }
    .form__digital_wallet--header {
        h2 {
            font-weight: 800;
        }
    }

    .form__digital_wallet--qrcode_title {
        h2 {
            font-size: 18px;
            display: inline-block;
            position: relative;
            top: -14px;
            font-size: 16px;
            font-weight: bold;
            line-height: 20px;
            margin-left: 8px;
        }
    }

    .form__digital_wallet--qrcode_content .form__digital_wallet--qrcode_warning p {
        font-size: 10px;
        line-height: 14px;
        letter-spacing: .2px;
        text-align: center;
        margin-left: 8px;
        color: #5c5c5c;
    }

    .form__digital_wallet--button_container p {
        font-size: 12px;
        line-height: 14px;
        font-weight: 300;
        margin-bottom: 8px;
    }

    .form__digital_wallet--button_copy {
        letter-spacing: 0;
        text-transform: none;
    }

    .form__digital_wallet--button_white {
        color: #2a2a2a;
        background: #fff;
        border: 1px solid #2a2a2a;
    }

    .form__digital_wallet--button {
        height: 48px;
        font-size: 14px;
    }

    p.form__digital_wallet--pix_code {
        font-size: 10px;
        line-height: 12px;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 4px;
        height: 20px;
        background-color: #f7f7f7;
    }

    .form__digital_wallet--instructions {
        display: flex;
        flex-direction: column;
        margin: 0 0 30px 32px;  
        
        .form__digital_wallet--steps img {
            position: absolute;
            top: -6px;
            left: -24px;
            background-color: #FFF;
        }
    
        .form__digital_wallet--steps {
            display: flex;
            flex-direction: row;
            position: relative;
            margin-top: 30px;
            align-items: center;
            min-height: 42px;
        }

        .form__digital_wallet--steps p {
            font-size: 12px;
            font-weight: 300;
            line-height: 16px;
            margin-left: 42px;
        }
            
    }
    
}