.page-layout-1column {
    #amasty-gallery-images, #amasty-main-container {
        text-align: left;
    }
}

.page-layout-1column, .page-layout-2columns-left, .page-layout-2columns-right, .page-layout-1column-fullwidth{
    overflow-x: initial;
}

.cms-index-index .product-item .price-box {
    justify-content: center;
}

.page-layout-1column .action.primary,
.page-layout-1column .action.primary:hover,
.page-layout-2columns-left .action.primary,
.page-layout-2columns-left .action.primary:hover {
    background: #000;
    border: 1px solid #000;
    border-radius: 3px;
}

.amasty-label-container{
    position: relative !important;
    display: inline-block !important;
    height: auto !important;
    width: auto !important;
    padding: 2px 5px !important;
}

.amasty-label-text{
    position: relative !important;
}