.amwrap-gift-button {
    position: relative;
    .amwrap-btn.-fill {
            font-weight: normal;
            margin: 3px 0;
            @include botao-padrao($black, $white, false, 43px);
            line-height: 0;

            @media (max-width: 768px) {
                width: 100%;
            }
    }
}

.amwrap-popup button:not(.primary) {
    box-shadow: none;
}

.amwrap-option-list .amwrap-option {
    border-color: $black;
    color: $black;
}

.amwrap-option-list .amwrap-option:hover {
    border-color: $black;
    color: $white;
    background-color: $black;
}

.amwrap-slider-block .amwrap-slide {
    text-align: center;
}

.amwrap-slider-block .amwrap-slide.-active, .amwrap-slider-block .amwrap-slide:hover {
    border-color: $black !important;
}

.amwrap-btn-toolbar .amwrap-btn.-fill {
    background: $black !important;
    color: $white !important;
}

.amwrap-btn.-primary:active .amwrap-chevron:after, .amwrap-btn.-primary:active .amwrap-chevron:before {
    color: $white;

}

.amwrap-message.-warning-message {
    text-align: center;
    color: $white;
    background-color: $black-80;
}

.amwrap-pagination-block .amwrap-page.-active {
    background-color: $black-80;
}

.amwrap-btn.-primary:active:hover, .amwrap-btn.-primary:active {
    background: $black !important;
    color: $white !important;
}

.amwrap-btn.-primary.-active {
    border: 1px solid $black;
    color: $white;
    background-color: $black;
}

.amwrap-btn.-primary:active {
    border-color: $black !important;
    background: $black;
    box-shadow: none;
}



.amwrap-btn.-empty {
    color: $black !important;
}

.amwrap-finish-message .amwrap-message:before {
    filter: grayscale(1) saturate(1);
}

.amwrap-trash-btn:hover:after, .amwrap-edit-btn:hover:after {
    color: $black-80;
    filter: none;
}

.amwrap-finish-message {
    background-color: $white;
    border: 1px dashed $black-80;
}

.amwrap-pagination-block .amwrap-page.-checked {
    background: $black-80 !important;
}

.amwrap-pagination-block .amwrap-page.-checked:before {
    width: 4px;
    top: 1px;
}

.amwrap-pagination-block .amwrap-page:after {
    background: $black !important;
}

.amwrap-chevron:after, .amwrap-chevron:before {
    background-color: $white !important;
}

.amwrap-btn.-primary:hover {
    background: $black !important;
    border-color: $black !important;
}

.amwrap-checkbox-block .amwrap-input:checked ~ .amwrap-mark {
    border-color: $black;
    background-color: $black;

}
